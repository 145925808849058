import { IonCol, IonRow, IonText } from "@ionic/react"
import BookCard from "./BookCard"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'

interface CarouselProps {
  title: string,
  books: any[]
  cardClick: (book: any) => void
}

const Carousel: React.FC<CarouselProps> = ({ title, books, cardClick }) => {

  const titleStyle = {
    textTransform: 'uppercase',
    margin: '0px 15px',
    padding: '0px',
    fontSize: 15
  }

  return (
    <div style={{ marginTop: 5 }}>
      <IonRow>
        <IonCol style={{ padding: '3px 10px 0px' }} size="12">
          <IonText style={titleStyle} ><strong>{title}</strong></IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12" class="ion-no-padding">
          <Swiper slidesPerView={1.2} spaceBetween={0}>
            {
              books.map((b: any, x: number) =>
                <SwiperSlide key={x}>
                  <BookCard book={b} onClick={() => cardClick(b)} style={{ maxHeight: 130 }} />
                </SwiperSlide>
              )
            }
          </Swiper>
        </IonCol>
      </IonRow>
    </div>
  )
}

export default Carousel