import { StatusBar } from "@capacitor/status-bar"
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonImg,
  IonBadge,
  useIonViewDidEnter,
  IonFooter
} from "@ionic/react"
import { mdiMapMarkerRadiusOutline } from "@mdi/js"
import Icon from "@mdi/react"
import {
  bookOutline,
  mailOutline,
  personCircleOutline
} from "ionicons/icons"
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import { Redirect, Route, useHistory, useLocation } from "react-router"
import ProtectedRoute from "./ProtectedRoute"
import Auth from "../pages/Auth"
import BookDetails from "../pages/BookDetails"
import BookPointDetails from "../pages/BookPointDetails"
import BookPoints from "../pages/BookPoints"
import Chat from "../pages/chat/Chat"
import ChatList from "../pages/chat/ChatList"
import FirstLogin from "../pages/FirstLogin"
import Home from "../pages/Home"
import Login from "../pages/Login"
import MyShelf from "../pages/MyShelf"
//import NewBookForm from './pages/newBook/NewBookForm';
import Review from "../pages/newBook/Review"
import SearchFromList from "../pages/newBook/SearchFromList"
import SearchIsbn from "../pages/newBook/SearchIsbn"
import PersonalProfile from "../pages/PersonalProfile"
import Settings from "../pages/Settings"
import Tutorial from "../pages/Tutorial"
import UserProfile from "../pages/UserProfile"
import { useAuth } from "../providers/AuthProvider"
import { useMessaging } from "../providers/FirebaseMessagingProvider"
import { App } from '@capacitor/app'
import Error from "../pages/Error"
import { Capacitor } from "@capacitor/core"
import { useDevice } from "../providers/DeviceProvider"
import QrcodeScanner from "../pages/QrcodeScanner"
import ReactErrorBoundary from "./ErrorBoundary"
import Communications from "../pages/Communications"
import { IonReactRouter } from "@ionic/react-router"

type TabsProviderOptions = {
  showTabs: Boolean | null | undefined
  setShowTabs: Function
  homeClicked?: Boolean
  setHomeClicked: Dispatch<SetStateAction<Boolean>>
};

const TabsContext = createContext<TabsProviderOptions | null>(null);

export const useTabs = () => useContext(TabsContext) as TabsProviderOptions;

const Main: React.FC = () => {
  const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);

  const { user, token } = useAuth()
  const { messagesCount, loadMessagesStatus } = useMessaging()
  const location = useLocation()
  const history = useHistory()
  const { isAndroid, isWeb, device, safeArea } = useDevice()

  const [showTabs, setShowTabs] = useState(false)
  const [pathname, setPathname] = useState<any>(null)
  const [homeClicked, setHomeClicked] = useState<Boolean>(false)

  useEffect(() => {
    // dato che non abbiamo utilizzato i layout questo componente viene montato in diverse pagine
    // questo vuol dire che il cambio di route scatena N useEffect a seconda di quanti componenti AppTabs sono stati montati
    // per evitare che navigando si accodino sempre più chiamate di message_status ogni componente AppTabs chiamerà message_status solo sulla route di sua competenza
    // es: se questo componente viene montato nella pagina bookPoints, attiverà la chiamata message_status ogni volta che history.location.pathname sarà uguale a /bookPoints
    if (user && token && (!pathname || pathname !== location.pathname) && isWeb) {
      loadMessagesStatus()

      setPathname(location.pathname)
    } else if (user && !isWeb)
      loadMessagesStatus()
  }, [user, token, location])

  useEffect(() => {
    if (Capacitor.isPluginAvailable('StatusBar') && isAndroid) {
      // StatusBar.setOverlaysWebView({ overlay: false })
      StatusBar.setBackgroundColor({ color: '#ed7459' })
    }
  }, [isAndroid])

  useIonViewDidEnter(() => {
    App.addListener('backButton', ({ canGoBack }) => {
      if (canGoBack) {
        history.goBack()
      } else {
        App.exitApp();
      }
    })
  })

  const tabBarPadding = () => {
    if (!showTabs) return '0px'
    // if(isAndroid) {
    //   return `min(${safeArea?.insets.bottom ?? 0}px, var(--ion-safe-area-top, 0))`
    // }
    return 'env(safe-area-inset-bottom)'
  }

  const routerOutletStyle = {
    height: 'calc(100% + 50px)',
    marginBottom: showTabs ? 50 : '',
  }

  const tabBarStyle = {
    height: showTabs ? '50px' : '0px',
    paddingBottom: tabBarPadding(),
    // paddingBottom: 'var(--ion-safe-area-bottom, 0)',
    transition: '0.2s'
  }

  return (
    <TabsContext.Provider value={{ showTabs, setShowTabs, homeClicked, setHomeClicked }}>
      <ReactErrorBoundary>
        <IonTabs>
          <IonRouterOutlet style={routerOutletStyle}>
            <Redirect exact from='/' to={token ? '/home' : '/login'} />
            <Route path="/login" component={Login} />
            <Route path="/home" component={Home} />

            <Route path="/auth" component={Auth} />
            <Route path="/error" component={Error} />
            <ProtectedRoute path="/firstLogin" component={FirstLogin} />

            <Route path="/hostShelf/:id" component={BookDetails} />
            <ProtectedRoute path="/new_book/search_isbn" component={SearchIsbn} />
            <ProtectedRoute path="/new_book/search_from_list" component={SearchFromList} />
            {/* <ProtectedRoute path="/new_book/new_book_form" component={NewBookForm} /> */}
            <ProtectedRoute path="/new_book/review" component={Review} />

            <ProtectedRoute exact path="/myShelf" component={MyShelf} />
            <Route exact path="/myShelf/:id" component={BookDetails} />
            <Route exact path="/bookPoints" component={BookPoints} />
            <Route path="/bookPoints/:id" component={BookPointDetails} />

            <ProtectedRoute exact path="/chats" component={ChatList} />
            <ProtectedRoute path="/scan_qr" component={QrcodeScanner} />
            <ProtectedRoute path="/chats/:id" render={() => <Chat router={routerRef.current} />} />
            <ProtectedRoute exact path="/profile" render={() => <PersonalProfile router={routerRef.current} />} />
            <Route path="/user/:id?" component={UserProfile} />
            <ProtectedRoute exact path="/profile/settings" component={Settings} />
            <Route path="/profile/settings/tutorial" component={Tutorial} />

            <ProtectedRoute exact path="/profile/communications" component={Communications} />
          </IonRouterOutlet>

          <IonTabBar slot="bottom" style={tabBarStyle}>
            <IonTabButton tab="myShelf" href="/myShelf">
              <IonIcon icon={bookOutline} />
            </IonTabButton>
            <IonTabButton tab="bookPoints" href="/bookPoints">
              <Icon size={1.4} path={mdiMapMarkerRadiusOutline} />
            </IonTabButton>
            <IonTabButton tab="home" href="/home" onClick={() => setHomeClicked(true)}>
              <IonImg style={{ height: "80%" }} src="/images/monogramma.png" />
            </IonTabButton>
            <IonTabButton tab="chat" href="/chats">
              <IonIcon icon={mailOutline} />
              {
                messagesCount > 0 &&
                <IonBadge color="primary">{messagesCount}</IonBadge>
              }
            </IonTabButton>
            <IonTabButton tab="profile" href="/profile">
              <IonIcon icon={personCircleOutline} />
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </ReactErrorBoundary>
    </TabsContext.Provider >
  )
}

export default Main