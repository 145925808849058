import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react"
import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import BookCard from "../../components/BookCard"
import SecondaryToolbar from "../../components/SecondaryToolbar"
import SecondaryLayout from "../../layout/SecondaryLayout"
import { useAuth } from "../../providers/AuthProvider"
import { useToast } from "../../providers/ToastProvider"
import {useDebounce} from 'usehooks-ts'

const SearchFromList: React.FC = () => {
  const debounce = 750

  const { user, token } = useAuth()
  const [books, setBooks] = useState<any>([])
  const filteredBooks = useMemo(() => {
    return books.map((i: any) => {
      const authors = Array.isArray(i?.volumeInfo?.authors) ? 
        i?.volumeInfo?.authors?.join(', ') :
        i?.volumeInfo?.authors
        
      return {
        title: i.volumeInfo.title,
        author: authors,
        abstract: i.volumeInfo.description,
        thumbnail: i.volumeInfo.imageLinks?.thumbnail,
        document: i
      }
    })
  }, [books])
  const [page, setPage] = useState({ page: 1 })
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState<string>("")
  const debouncedValue = useDebounce<string>(search, debounce)
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
  const toast = useToast()
  const history = useHistory()
  const [infiniteEvent, setInfiniteEvent] = useState<any>()

  const loadMoreBooks = async (event: any) => {
    setPage({ page: page.page + 1 })
    setInfiniteEvent(event)
  }

  const loadBooks = async (request: any) => {
    setLoading(true)

    try {
      const { data, meta }: any = await axios.get('books/search', {
        params: {
          search,
          page: page.page
        },
        cancelToken: request.token
      })
      setBooks([...books, ...data.items])
      setDisableInfiniteScroll(data.items.length < meta.limit || data.items.length === 0)

      setLoading(false)
    } catch (error: any) {
      if (!error.__CANCEL__) {
        console.debug(error)

        toast.error("Errore nel caricamento dei libri")

        setLoading(false)
      }
    }

    infiniteEvent?.target?.complete()
  }

  const refreshBooks = async (event: any) => {
    refresh()
    event?.detail.complete()
  }

  const refresh = async () => {
    setBooks([])
    setPage({ page: 1 })
  }

  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    if (user) {
      loadBooks(ourRequest)
    }

    return () => {
      ourRequest.cancel()
    }
  }, [user, token, page])

  useEffect(() => {
    refresh()
  }, [debouncedValue])

  const buttonStyle = {
    marginTop: 7,
    marginLeft: 50,
    marginRight: 50,
    fontSize: 14
  }

  return (
    <SecondaryLayout
      toolbar={
        <>
          <SecondaryToolbar className="secondary-toolbar">
            <IonButtons slot="start">
              <IonBackButton text="" color="dark" />
            </IonButtons>
            <IonTitle>
              Aggiungi un libro
            </IonTitle>
          </SecondaryToolbar>
          <IonToolbar class="light-toolbar">
            <IonSearchbar
              placeholder="Digita il titolo o un autore"
              style={{ marginTop: 10 }}
              debounce={debounce}
              onIonInput={e => {
                setSearch(e.target.value!)
              }}
              onIonClear={() => { setSearch("") }}
            />
          </IonToolbar>
        </>
      }
      loading={loading}
    >
      <IonRefresher slot="fixed" onIonRefresh={refreshBooks}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {filteredBooks.length === 0 && search !== "" && !loading &&
        <IonGrid>
          <IonRow class="ion-justify-content-center ion-margin-top">
            <IonCol size="8">
              <IonImg src="/images/empty-shelf.svg" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" class="ion-text-center">
              <IonText>
                Non abbiamo trovato nessun libro per questa ricerca
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      <IonList>
        {filteredBooks.map((book: any, index: number) => {
          return (
            <BookCard
              style={{ margin: '10px 0px' }}
              isInLibrary={false}
              key={index}
              book={book}
              onClick={() => history.push('/new_book/review', { book, type: 'list' })}
            />
          )
        })}
      </IonList>
      <IonInfiniteScroll
        onIonInfinite={loadMoreBooks}
        threshold="100px"
        disabled={disableInfiniteScroll}
      >
        <IonInfiniteScrollContent
          loadingSpinner="circular"
          loadingText="Caricamento in corso..."
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      {filteredBooks.length !== 0 && disableInfiniteScroll &&
        <IonButton
          style={buttonStyle}
          size="small"
          shape="round"
          expand="block"
          onClick={() => history.push('/new_book/new_book_form')}
        >
          Non lo trovi?
        </IonButton>
      }
    </SecondaryLayout>
  )
}

export default SearchFromList