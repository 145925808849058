import { IonToast } from "@ionic/react"
import { informationCircle } from "ionicons/icons"
import { Dispatch, SetStateAction } from "react"

interface ToastTutorialProps {
  showBanner: boolean
  setShowBanner: Dispatch<SetStateAction<boolean>>
}

const ToastTutorial: React.FC<ToastTutorialProps> = ({ showBanner, setShowBanner }) => {

  return (
    <IonToast
      isOpen={showBanner}
      onDidDismiss={() => setShowBanner(false)}
      message="Consulta i nostri <a href='/profile/settings/tutorial'>tutorial</a> per una migliore esperienza"
      color="info"
      icon={informationCircle}
      position="bottom"
      cssClass="banner-tutorial"
      buttons={[
        {
          text: 'Annulla',
          role: 'cancel'
        }
      ]}
    />
  )
}

export default ToastTutorial