import {
  IonToolbar,
  IonSearchbar,
  IonIcon,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSegment,
  IonSegmentButton
} from "@ionic/react"
import { funnel, funnelOutline } from "ionicons/icons"
import { Dispatch, SetStateAction } from "react"
import { BookPointFilterParams } from "../pages/BookPoints"

interface BookPointToolbarProps {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  filter: any
  setFilter: Dispatch<SetStateAction<BookPointFilterParams>>
  selectedSegment: string | undefined
  setSelectedSegment: Dispatch<SetStateAction<string | undefined>>
}

const BookPointToolbar: React.FC<BookPointToolbarProps> = ({ search, setSearch, filter, setFilter, selectedSegment, setSelectedSegment }) => {

  const funnelIconStyle = {
    fontSize: 22,
    paddingTop: 20,
    paddingBottom: 5,
    paddingLeft: 3,
    paddingRight: 10
  }

  return (
    <>
      <IonToolbar class="light-toolbar">
        <IonSearchbar
          style={{ padding: 10, paddingBottom: 0 }}
          placeholder="Cerca tra i BookPoint"
          debounce={750}
          value={search}
          onIonChange={e => {
            setSearch(e.detail.value!)
          }}
          showCancelButton="never"
        />
        <IonIcon
          slot="end"
          id="trigger-filter-bookpoints"
          style={funnelIconStyle}
          color={filter.opened || filter.has_shelfs ? 'primary' : 'grey'}
          icon={filter.opened || filter.has_shelfs ? funnel : funnelOutline}
        />
        <IonPopover trigger="trigger-filter-bookpoints">
          <IonContent class="ion-text-center" scrollY={false}>
            <IonList>
              <IonItem>
                <IonLabel>
                  <small>Aperti Ora</small>
                </IonLabel>
                <IonCheckbox
                  checked={filter?.opened === 1}
                  slot="end"
                  color="primary"
                  onIonChange={(e) => { setFilter({ ...filter, opened: e.detail.checked ? 1 : 0 }) }}
                />
              </IonItem>
              <IonItem>
                <IonLabel>
                  <small>Ha libri Disponibili</small>
                </IonLabel>
                <IonCheckbox
                  checked={filter?.has_shelfs === 1}
                  slot="end"
                  color="primary"
                  onIonChange={(e) => { setFilter({ ...filter, has_shelfs: e.detail.checked ? 1 : 0 }) }}
                />
              </IonItem>
            </IonList>
          </IonContent>
        </IonPopover>
      </IonToolbar>
      <IonToolbar
        class="light-toolbar bookpoints-toolbar"
        style={{ height: 45, minHeight: 45 }}
      >
        <IonSegment
          style={{ margin: '0px 10px', width: '95%' }}
          value={selectedSegment}
          onIonChange={(val) => setSelectedSegment(val.detail.value)}
        >
          <IonSegmentButton
            style={{ height: 35, minHeight: 35 }}
            value="list" >
            Lista
          </IonSegmentButton>
          <IonSegmentButton
            style={{ height: 35, minHeight: 35 }}
            value="map"
          >
            Mappa
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>
    </>
  )
}

export default BookPointToolbar
