import {
  IonButton
} from "@ionic/react"

interface ChatOptionProps {
  options: any[]
}

const ChatOption: React.FC<ChatOptionProps> = ({ options }) => {
  return (
    <>
      {
        options.map((option: any, index: number) => {
          return (
            <IonButton key={index} shape="round" size="small" expand='block' onClick={option.onClick}>
              {option.label}
            </IonButton>
          )
        })
      }
    </>
  )
}

export default ChatOption