import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonText
} from "@ionic/react"
import { FallbackProps } from "react-error-boundary"
import EmptyLayout from "../layout/EmptyLayout"
import { bugOutline, refreshOutline } from "ionicons/icons"

const ErrorPage: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <EmptyLayout>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', margin: '10px', height: "90%" }}>
        <div>
        <IonCard color="danger" style={{ flex: '1 0 100%' }}>
          <IonCardHeader>
            <IonIcon size="large" icon={bugOutline} />
            <IonCardTitle>Error</IonCardTitle>
          </IonCardHeader>
          <IonCardContent style={{backgroundColor: 'white', color: '#bf0202', margin: 10, padding: 10, borderRadius: 10}}>
            <IonText>{error?.message}</IonText>
          </IonCardContent>
        </IonCard>
        <IonButton onClick={resetErrorBoundary}>
          <IonIcon slot="start" icon={refreshOutline} />
          Refresh
        </IonButton>
        </div>
      </div>
    </EmptyLayout>
  )
}

export default ErrorPage
