import {
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonGrid,
  IonImg,
  IonAvatar,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
} from "@ionic/react"
import {
  bookOutline,
  cafeOutline,
  checkmarkOutline,
  globeOutline,
  locationOutline,
  pawOutline,
  timeOutline,
  wifiOutline,
  wineOutline
} from "ionicons/icons"
import axios from "axios"
import { addIcons } from 'ionicons'
import React from "react"
import { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import { Swiper, SwiperSlide } from "swiper/react"
import { Service } from "../components/BookPointCard"
import { useToast } from "../providers/ToastProvider"
import SecondaryLayout from "../layout/SecondaryLayout"
import SecondaryToolbar from "../components/SecondaryToolbar"
import BookpointShelfsModal from "../components/BookpointShelfsModal"
import { useDevice } from "../providers/DeviceProvider"

const BookPointDetails: React.FC<RouteComponentProps> = ({ match }) => {
  const [loading, setLoading] = useState(false)
  const [bookPoint, setBookPoint] = useState<any>({})
  const [showShelfsModal, setShowShelfsModal] = useState(false)
  const { safeArea } = useDevice()
  const toast = useToast()
  // const gaEventTracker = useAnalyticsEventTracker('Bookpoint');
  // const cookies = new Cookies();
  const params: any = match.params

  const loadBookPointDetails = async () => {
    try {
      const { data } = await axios.get(`/bookpoints/${params.id}`)
      setBookPoint(data)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero del dettaglio del BookPoint")
    }
  }

  useEffect(() => {
    loadBookPointDetails()
    setLoading(false)
  }, [])

  // useIonViewDidEnter(() => {
  //   if (cookies.get("user-has-accepted-cookies")) {
  //     gaEventTracker('Open Bookpoint Details')
  //   }
  // })

  const descriptionStyle = {
    backgroundColor: "var(--ion-color-primary)",
    color: "black",
    borderRadius: "0px 10px 10px",
    padding: 10,
    TextAlign: "left",
    fontSize: 13,
  }

  const iconStyle = {
    marginRight: 6,
    fontSize: 16
  }

  const titleStyle = {
    fontWeight: 400,
    padding: "3px 60px"
  }

  const imgStyle = {
    display: 'block',
    objectFit: 'cover',
    height: 300,
  }

  addIcons({
    'time-outline': timeOutline,
    'location-outline': locationOutline,
    'globe-outline': globeOutline,
    'book-outline': bookOutline,
    'cafe-outline': cafeOutline,
    'wifi-outline': wifiOutline,
    'paw-outline': pawOutline,
    'wine-outline': wineOutline,
    'checkmark-outline': checkmarkOutline
  })

  return (
    <SecondaryLayout
      toolbar={
        <SecondaryToolbar className="secondary-toolbar">
          {!loading &&
            <IonButtons slot="start">
              <IonBackButton
                color="dark"
                text=""
                defaultHref="/bookPoints"
              />
            </IonButtons>
          }
          <IonTitle style={titleStyle}>
            {bookPoint.full_name}
          </IonTitle>
        </SecondaryToolbar>
      }
      loading={loading}
    >
      <IonCard class="ion-no-margin">
        <IonAvatar style={{ position: "absolute", margin: 15, zIndex: 999 }}>
          <IonImg src="/assets/icon/favicon.png" style={{ width: 60, height: 60 }} />
        </IonAvatar>
        {bookPoint.carousel_images ?
          <Swiper pagination style={{ height: 300 }}>
            {
              bookPoint.carousel_images?.map((image: any, i: number) => {
                return (
                  <SwiperSlide key={i}>
                    <IonImg src={image} style={imgStyle} />
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          :
          <IonImg src="/images/bookpoint-no-picture.jpg" />
        }
        <IonCardHeader
          color="light"
          style={{ background: "#eaeaea", padding: 10 }}
        >
          {bookPoint.full_name}
        </IonCardHeader>
        <IonCardContent style={{ padding: 0 }}>
          <IonGrid style={{ padding: "10px 0px" }}>
            <IonRow>
              <IonCol size="9">
                <IonRow class="ion-align-items-center" >
                  <IonCol class="ion-no-padding">
                    <IonIcon
                      style={{ ...iconStyle, verticalAlign: "middle", paddingBottom: 3 }}
                      icon="time-outline"
                      color="secondary"
                    />
                    <IonText>
                      {
                        bookPoint.current_timetable ?
                          `${bookPoint.current_timetable?.label} ${bookPoint.current_timetable?.start} - ${bookPoint.current_timetable?.end}`
                          :
                          "Oggi Chiuso"
                      }
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow class="ion-align-items-center">
                  <IonCol size="1" class="ion-no-padding">
                    <IonIcon
                      style={{ ...iconStyle, verticalAlign: "middle", paddingBottom: 3 }}
                      icon="location-outline"
                      color="secondary"
                    />
                  </IonCol>
                  <IonCol class="ion-no-padding">
                    <IonText>
                      {bookPoint.address} {bookPoint.city} {bookPoint.province}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow class="ion-align-items-center">
                  <IonCol class="ion-no-padding">
                    <IonIcon
                      style={{ ...iconStyle, verticalAlign: "middle", paddingBottom: 3 }}
                      icon="globe-outline"
                      color="secondary"
                    />
                    <a target="_blank" href={bookPoint.web_site}>{bookPoint.web_site?.replace('https://', '')}</a>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol class="ion-text-center">
                <IonButton
                  color="light"
                  onClick={() => setShowShelfsModal(true)}
                >
                  <IonIcon
                    icon="book-outline"
                    color="secondary"
                    style={{ fontSize: 32 }}
                  />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>

          <div style={descriptionStyle}>
            <IonText>
              {bookPoint.bio}
            </IonText>
          </div>

          <IonGrid class="ion-padding-top">
            <IonRow>
              {
                bookPoint.services?.map((s: Service, i: number) =>
                  <React.Fragment key={i}>
                    <IonCol size="1" class="ion-no-padding" style={{ maxWidth: 25 }} >
                      <IonIcon style={iconStyle} icon={s.icon} color="secondary" />
                    </IonCol>
                    <IonCol size="5" class="ion-no-padding">
                      <IonLabel>
                        {s.label}
                      </IonLabel>
                    </IonCol>
                  </React.Fragment>
                )
              }
            </IonRow>
          </IonGrid>

        </IonCardContent>
      </IonCard>
      {
        bookPoint.stats &&
        <IonGrid style={{ paddingBottom: (safeArea?.insets.bottom ?? 0) + 10 }}>
          <IonToolbar class="light-toolbar">
            <IonTitle style={titleStyle}>
              Statistiche
            </IonTitle>
          </IonToolbar>
          <IonList>
            <IonItem>
              <IonLabel>
                <h5>
                  Libri messi a disposizione
                </h5>
              </IonLabel>
              <IonBadge color="primary" slot="end">
                {bookPoint.stats.shelfs}
              </IonBadge>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h5>
                  Scambi avvenuti presso questo Bookpoint
                </h5>
              </IonLabel>
              <IonBadge color="primary" slot="end">
                {bookPoint.stats.borrows}
              </IonBadge>
            </IonItem>
          </IonList>
        </IonGrid>
      }

      <BookpointShelfsModal
        isOpen={showShelfsModal}
        onDidDismiss={() => setShowShelfsModal(false)}
        bookPoint={bookPoint}
      />
    </SecondaryLayout>
  )
}

export default BookPointDetails