import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from "@ionic/react"
import useLocalStorageState from "use-local-storage-state"
import { Communication } from "./CommunicationDetails"
import { useHistory } from "react-router"
import { chevronForward, newspaperOutline } from "ionicons/icons"
import { useEffect, useRef, useState } from "react"
import SecondaryLayout from "../layout/SecondaryLayout"
import SecondaryToolbar from "../components/SecondaryToolbar"

const Communications: React.FC = () => {
  const history: any = useHistory()
  const [showModal, setShowModal] = useState(false)
  const communicationModalRef = useRef<HTMLIonContentElement>(null)
  const [selectedCommunication, setSelectedCommunication] = useState<Communication | null | undefined>(null)

  const [communicationsArchive] = useLocalStorageState<Array<Communication>>('communicationsArchive', {
    defaultValue: []
  })

  // const [present] = useIonAlert()

  // const emptyArchive = async () => {
  //   await present({
  //     header: "Confermi di voler svuotare l'archivio comunicazioni?",
  //     buttons: [
  //       'Indietro',
  //       {
  //         text: 'Svuota', 
  //         handler: () => {
  //           removeItem()
  //         }
  //       },
  //     ]
  //   })
  // }

  useIonViewDidEnter(() => {
    if (history.location.state?.id) {
      showDetails(communicationsArchive.find(c => c.id === history.location.state?.id))
      delete history.location.state?.id
    }
  })

  const showDetails = (c: Communication | undefined) => {
    setSelectedCommunication(c)
    setShowModal(true)
  }

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        communicationModalRef && communicationModalRef.current && (communicationModalRef.current.ontouchmove = function (e: any) {
          e.stopPropagation();
        })
      }, 100)
    }
  }, [showModal])

  const iconStyle = {
    fontSize: 24,
  }

  const toolbarModalStyle = {
    borderRadius: 0
  }

  const backIconStyle = {
    fontSize: 20,
    padding: 10
  }

  return (
    <SecondaryLayout
      toolbar={
        <SecondaryToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" color="light" defaultHref="/profile" />
          </IonButtons>
          <IonTitle>
            Comunicazioni
          </IonTitle>
          {/* { communicationsArchive.length !== 0 && <IonIcon
            style={{ fontSize: 23, paddingRight: 13 }}
            slot="end"
            icon={trashBinOutline}
            onClick={emptyArchive}
          />
         } */}
        </SecondaryToolbar>
      }
    >
      {communicationsArchive.length === 0 ? <IonGrid>
        <IonRow>
          <IonCol size="12" class="ion-text-center">
            <IonText>
              Nessuna comunicazione in archivio
            </IonText>
          </IonCol>
        </IonRow>

        <IonRow class="ion-justify-content-center ion-margin-top">
          <IonCol size="8" class="ion-text-center">
            <IonIcon color="grey" style={{ fontSize: 100 }} icon={newspaperOutline} />
          </IonCol>
        </IonRow>
      </IonGrid>
        :
        <IonList>
          {communicationsArchive.map((communication, index: number) => {
            return (
              <IonItem
                key={index}
                onClick={() => showDetails(communication)}
              >
                <IonLabel>
                  <h2>{communication.title}</h2>
                  <p>{communication.short_message}</p>
                </IonLabel>

                <IonIcon style={iconStyle} color="grey" icon={chevronForward} slot="end" />
              </IonItem>
            )
          })
          }
        </IonList>
      }
      <IonModal
        isOpen={showModal}
        onDidDismiss={async () => {
          setShowModal(false)
        }}
        initialBreakpoint={0.60}
        breakpoints={[0, 0.60]}
      >
        <IonHeader>
          <IonToolbar class="light-toolbar" style={toolbarModalStyle}>
            <IonTitle>Dettaglio</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent ref={communicationModalRef} class="ion-text-center">
          {selectedCommunication ?
            <>
              <h2 className="ion-padding-bottom">
                {selectedCommunication?.title}
              </h2>
              <IonText>
                {selectedCommunication?.body}
              </IonText>
            </>
            :
            <IonCol size="5">
              <IonRow class="ion-justify-content-center ion-padding-bottom">
                <IonSkeletonText animated style={{ height: 25, width: 130 }} />
              </IonRow>
              <IonRow class="ion-justify-content-center">
                <IonSkeletonText animated style={{ height: 13, width: 130 }} />
              </IonRow>
            </IonCol>
          }
        </IonContent>
      </IonModal>
    </SecondaryLayout>
  )
}

export default Communications