import {
  IonAvatar,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton
} from "@ionic/react"
import {
  checkmarkCircleOutline,
  chevronForward,
  locationOutline,
  mailOpenOutline
} from "ionicons/icons";
import { useState } from "react";
import BookCard from "./BookCard";
import { useHistory } from "react-router";
import { useDevice } from "../providers/DeviceProvider";

interface ProfileDetailsProps {
  isMyProfile?: boolean
  profile: any | null
  starredBooks?: any[]
  loading: boolean
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({ isMyProfile, profile, starredBooks, loading }) => {

  const [selectedSegment, setSelectedSegment] = useState<string | undefined>('profile')
  const history = useHistory()
  const {isWeb} = useDevice()

  const goToSearch = ({ title }: { title: string }) => {
    history.push("/home", { search: title })
  }

  const descriptionStyle = {
    backgroundColor: "var(--ion-color-primary)",
    color: "black",
    borderRadius: "0px 10px 10px",
    padding: 10,
    TextAlign: "left",
    fontSize: 13,
    marginBottom: 15
  }

  const iconStyle = {
    fontSize: 24,
  }

  const infoCardStyle = {
    width: "70%",
    margin: "auto",
    color: "secondary",
    padding: 10
  }

  return (
    <div className="ion-text-center">
      <IonAvatar style={{ margin: "10px auto", width: 100, height: 100 }}>
        <img src={profile?.avatar ?? "/images/no-avatar.png"} />
      </IonAvatar>
      <IonRow class="ion-justify-content-center">
        <IonLabel style={{ fontSize: 20 }}>
          {profile?.alias}
        </IonLabel>
      </IonRow>
      <br />
      {isMyProfile &&
        <IonSegment
          value={selectedSegment}
          onIonChange={(event) => setSelectedSegment(event.detail.value)}
        >
          <IonSegmentButton value="profile">
            Il tuo Profilo
          </IonSegmentButton>
          <IonSegmentButton value="starred-books">
            I tuoi Mi Piace
          </IonSegmentButton>
        </IonSegment>

      }
      {selectedSegment === 'profile' ?
        <div style={{ padding: '10px 0 0 0' }}>
          <IonRow class="ion-align-items-center">
            <IonCol style={descriptionStyle}>
              {profile?.bio ? profile?.bio : "Ancora nessuna bio"}
            </IonCol>
          </IonRow>
          {/* {isMyProfile &&
            <>
              <IonRow class="ion-align-items-center">
                <IonCol>
                  <IonItem style={{ fontSize: 14, "--padding-start": 0 }} lines="none">
                    <IonIcon style={iconStyle} color="secondary" icon={locationOutline} slot="start" />
                    Piazza Giolitti Bra
                  </IonItem>
                </IonCol>
              </IonRow>
              <hr style={{ backgroundColor: "#9d9d9d", marginTop: 7, marginBottom: 20 }} />
              <IonRow class="ion-align-items-center">
                <IonCol class="ion-no-padding">
                  <IonItem style={{ fontSize: 14, maxHeight: 35, "--padding-start": 0 }} lines="none">
                    <IonIcon style={iconStyle} color="secondary" icon={checkmarkCircleOutline} slot="start" />
                    Documento d'identita verificato
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow class="ion-align-items-center">
                <IonCol class="ion-no-padding">
                  <IonItem style={{ fontSize: 14, maxHeight: 35, "--padding-start": 0 }} lines="none">
                    <IonIcon style={iconStyle} color="secondary" icon={checkmarkCircleOutline} slot="start" />
                    {profile.email}
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          } */}
          {isMyProfile &&
            <>
              {
                profile?.address && profile?.city &&
                <>
                  <IonRow class="ion-align-items-center">
                    <IonCol class="ion-no-padding">
                      <IonItem style={{ fontSize: 14, "--padding-start": 0 }} lines="none">
                        <IonIcon style={iconStyle} color="secondary" icon={locationOutline} slot="start" />
                        {profile?.address} - {profile?.city}
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonItemDivider style={{ marginTop: 7, marginBottom: 7, minHeight: 2 }}></IonItemDivider>
                </>
              }
              <IonRow class="ion-align-items-center">
                <IonCol class="ion-no-padding">
                  <IonItem style={{ fontSize: 14, "--padding-start": 0 }} lines="none">
                    <IonIcon style={iconStyle} color="secondary" icon={checkmarkCircleOutline} slot="start" />
                    {profile?.email}
                  </IonItem>
                </IonCol>
              </IonRow>

              {!isWeb && 
                <>
                  <hr style={{ backgroundColor: "#9d9d9d", marginTop: 7, marginBottom: 25 }} />
                  <IonRow class="ion-align-items-center">
                    <IonCol class="ion-no-padding">
                      <IonItem style={{ maxHeight: 35, "--padding-start": 0 }} onClick={() => {
                        history.push('/profile/communications')
                      }}>
                        <IonIcon style={iconStyle} color="primary" icon={mailOpenOutline} slot="start" />
                        Archivio comunicazioni
                        <IonIcon style={iconStyle} class="ion-no-margin" color="grey" icon={chevronForward} slot="end" />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </>
              }
            </>
          }
        </div>
        :
        <IonGrid style={{ padding: "16px 0px 0px" }} class="ion-text-left">
          {starredBooks?.length === 0 && !loading ?
            <IonCard class="ion-text-center" color="light-secondary" style={infoCardStyle}>
              Quando incontri un libro che ti piace usa il cuore per indicare le tua preferenza!
            </IonCard>
            :
            starredBooks?.map((b: any, x: number) =>
              <BookCard key={x} book={b} onClick={() => goToSearch(b)} style={{ marginLeft: 0, marginRight: 0 }} />
            )
          }
        </IonGrid>
      }
    </div >
  )
}

export default ProfileDetails