import {
  IonContent,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonModal,
  IonFab,
  IonFabButton,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewDidEnter,
} from "@ionic/react"
import {
  closeOutline,
  createOutline,
  settingsOutline
} from "ionicons/icons"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { useToast } from "../providers/ToastProvider"
import ProfileDetails from "../components/ProfileDetails"
import FormProfile from "../components/FormProfile"
import { useAuth } from "../providers/AuthProvider"
import SecondaryLayout from "../layout/SecondaryLayout"
import SecondaryToolbar from "../components/SecondaryToolbar"
import { useDevice } from "../providers/DeviceProvider"

type pageParam = {
  page: number
}

interface Props {
  router?: HTMLIonRouterOutletElement | null;
}

const PersonalProfile: React.FC<Props> = () => {

  const defaultPage: pageParam = { page: 1 }

  const [loading, setLoading] = useState(false)
  const [starredBooks, setStarredBooks] = useState<any[]>([])
  const [showModal, setShowModal] = useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
  const [page, setPage] = useState<pageParam>(defaultPage)
  const userModalRef = useRef<HTMLIonContentElement>(null)
  const history: any = useHistory()
  const toast = useToast()
  const { b2bUser, refreshB2bUser } = useAuth()
  const { isAndroid } = useDevice()

  const loadMoreStarredBooks = (event: any) => {
    setPage({ page: page.page + 1 })
    event.target.complete();
  }

  const loadStarredBooks = async () => {
    setLoading(true)

    try {
      const { data, meta }: any = await axios.get('/books/starred', {
        params: {
          page: page.page
        }
      })
      setStarredBooks([...starredBooks, ...data])
      setDisableInfiniteScroll(data.length < meta.limit || data.length === 0)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero dei libri preferiti")
    }

    setLoading(false)
  }

  const refresh = async () => {
    refreshB2bUser()
    setStarredBooks([])
    setPage({ page: 1 })
  }

  const refreshProfile = async (event: CustomEvent<RefresherEventDetail> | null = null) => {
    refresh()
    event?.detail.complete()
  }

  const savedUser = () => {
    refreshProfile()
    setShowModal(false)
    if (history.location.state?.returnTo) {
      const returnTo = history.location.state.returnTo
      delete history.location.state.returnTo;
      history.push(returnTo, { refresh_user: true })
    }
  }

  useEffect(() => {
    if (b2bUser)
      loadStarredBooks()
  }, [page])

  useEffect(() => {
    refreshB2bUser()
    if (showModal) {
      setTimeout(() => {
        userModalRef && userModalRef.current && (userModalRef.current.ontouchmove = function (e: any) {
          e.stopPropagation();
        })
      }, 100)
    }
  }, [showModal])

  useIonViewDidEnter(() => {
    if (history.location.state && history.location.state.modalOpen) {
      setShowModal(true)
      let state = { ...history.location.state };
      delete state.modalOpen;
      history.replace({ ...history.location, state });
    } else {
      setShowModal(false)
    }
  })

  const backIconStyle = {
    fontSize: 20,
    padding: 10
  }

  const toolbarModalStyle = {
    borderRadius: 0
  }

  return (
    <SecondaryLayout
      toolbar={
        <SecondaryToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" />
          </IonButtons>
          <IonTitle>
            {b2bUser?.alias}
          </IonTitle>
          <IonIcon
            style={{ fontSize: 23, paddingRight: 13 }}
            slot="end"
            icon={settingsOutline}
            onClick={() => history.push("/profile/settings")}
          />
        </SecondaryToolbar>
      }
      loading={loading}
      showTabs
    >
      <IonRefresher slot="fixed" onIonRefresh={refreshProfile}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <ProfileDetails
        isMyProfile
        profile={b2bUser}
        starredBooks={starredBooks}
        loading={loading}
      />
      <IonFab vertical="bottom" horizontal="end" slot="fixed" style={{ bottom: 63 }}>
        <IonFabButton color="primary" onClick={() => setShowModal(true)}>
          <IonIcon style={{ padding: "0px 0px 3px 3px" }} icon={createOutline} />
        </IonFabButton>
      </IonFab>
      <IonModal
        isOpen={showModal}
        onDidDismiss={async () => {
          setShowModal(false)
        }}
        initialBreakpoint={isAndroid ? 0.95 : 0.99}
        breakpoints={[0, isAndroid ? 0.95 : 0.99]}
        backdropBreakpoint={0.75}
      >
        <IonHeader>
          <IonToolbar style={toolbarModalStyle}>
            <IonTitle>Modifica Profilo</IonTitle>
            <IonIcon
              style={backIconStyle}
              slot="end"
              icon={closeOutline}
              onClick={() => { setShowModal(false) }}
            />
          </IonToolbar>
        </IonHeader>
        <IonContent ref={userModalRef} class="ion-padding">
          <FormProfile user={b2bUser} isModal onModalSubmit={savedUser} />
        </IonContent>
      </IonModal>
      {!disableInfiniteScroll &&
        <IonInfiniteScroll
          threshold="1%"
          onIonInfinite={loadMoreStarredBooks}
          disabled={disableInfiniteScroll}
        >
          <IonInfiniteScrollContent
            loadingSpinner="dots"
            loadingText="Caricamento in corso..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      }
    </SecondaryLayout>
  )
}

export default PersonalProfile