import {
  IonBackButton,
  IonButtons,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSkeletonText,
  IonTitle,
  RefresherEventDetail,
} from "@ionic/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router"
import ProfileDetails from "../components/ProfileDetails"
import SecondaryToolbar from "../components/SecondaryToolbar"
import SecondaryLayout from "../layout/SecondaryLayout"
import { useAuth } from "../providers/AuthProvider"
import { useToast } from "../providers/ToastProvider"
import BookCard from "../components/BookCard"

type pageParam = {
  page: number
}

const UserProfile: React.FC<RouteComponentProps> = ({ match }) => {
  const defaultPage: pageParam = { page: 1 }

  const [loading, setLoading] = useState(false)
  const [userProfile, setUserProfile] = useState<any>({})
  const [userShelfs, setUserShelfs] = useState<any>([])
  const [page, setPage] = useState<pageParam>(defaultPage)
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
  const { user } = useAuth()
  const toast = useToast()
  const history = useHistory()
  const params: any = match.params

  const loadProfile = async () => {
    try {
      const { data } = await axios.get(`/users/${params.id}`)
      setUserProfile(data)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero delle info utente")
    }
  }
  const loadUserShelfs = async () => {
    setLoading(true)

    try {
      const { data, meta }: any = await axios.get(`/users/${params.id}/shelfs`, {
        params: {
          page: page.page
        }
      })
      setUserShelfs([...userShelfs, ...data])
      setDisableInfiniteScroll(data.length < meta.limit || data.length === 0)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero dei libri preferiti")
    }

    setLoading(false)
  }

  const loadMoreUserShelfs = (event: any) => {
    setPage({ page: page.page + 1 })
    event.target.complete();
  }

  const refresh = async () => {
    loadProfile()
    setUserShelfs([])
    setPage({ page: 1 })
  }

  const refreshProfile = async (event: CustomEvent<RefresherEventDetail> | null = null) => {
    refresh()
    event?.detail.complete()
  }

  useEffect(() => {
    if (user) {
      setLoading(true)
      loadProfile()
      setLoading(false)
    }
  }, [user])

  useEffect(() => {
    loadUserShelfs()
  }, [page])

  return (
    <SecondaryLayout
      toolbar={
        <SecondaryToolbar className="secondary-toolbar">
          <IonButtons slot="start">
            <IonBackButton color="dark" text="" />
          </IonButtons>
          <IonTitle>
            {userProfile.alias}
          </IonTitle>
        </SecondaryToolbar>
      }
      loading={loading}
      showTabs
    >
      <ProfileDetails profile={userProfile} loading={loading} />
      <div
        className="ion-no-padding"
        style={{ textTransform: "uppercase", fontSize: 16 }}
      >
        Libreria personale
      </div>
      {!loading ?
        <>
          <IonRefresher slot="fixed" onIonRefresh={refreshProfile}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          {
            userShelfs.map((s: any, x: number) =>
              <BookCard
                key={x}
                book={s.book}
                onClick={() => {
                  history.push(`/hostShelf/${s.id}`)
                }}
                style={{ marginLeft: 0, marginRight: 0 }}
              />
            )
          }
          {!disableInfiniteScroll &&
            <IonInfiniteScroll
              threshold="1%"
              onIonInfinite={loadMoreUserShelfs}
              disabled={disableInfiniteScroll}
            >
              <IonInfiniteScrollContent
                loadingSpinner="dots"
                loadingText="Caricamento in corso..."
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          }
        </>
        :
        <IonGrid class="ion-no-padding" style={{ paddingBottom: 30 }}>
          {
            Array.from({ length: 4 }, (_, index) =>
              <IonRow key={index} style={{ margin: '13px 0px' }}>
                <IonSkeletonText
                  class="ion-no-margin"
                  animated
                  style={{ height: 160, borderRadius: 30 }}
                />
              </IonRow>
            )
          }
        </IonGrid>
      }
    </SecondaryLayout>
  )
}

export default UserProfile