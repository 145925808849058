import {
  IonGrid,
  IonCol,
  IonImg,
  IonRow,
  IonText,
  RefresherEventDetail,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react"
import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import ChatCard from "../../components/ChatCard"
import { borrowStatuses } from "../../enums/BorrowStatuses"
import DefaultLayout from "../../layout/DefaultLayout"
import { B2BUser, useAuth } from "../../providers/AuthProvider"
import { useMessaging } from "../../providers/FirebaseMessagingProvider"
import { useToast } from "../../providers/ToastProvider"

export type Borrow = {
  id: string
  accepted_at: string | null
  bookpoint_id: string
  canceled_at: string | null
  created_at: string | null
  days: number
  extended_to: string | null
  extension_from: string | null
  from: string | null
  last_message: string
  last_message_at: string | null
  last_read_at: string | null
  qrcode: string
  readed: boolean
  rejected_at: string | null
  request_expired_at: string | null
  returned_at: string | null
  shelf: any
  status: number
  sync_address: string | null
  sync_address_at: string | null
  synced_at: string | null
  to: string | null
  updated_at: string | null
  user: B2BUser
}

const ChatList: React.FC = () => {
  const { user, b2bUser, token } = useAuth()
  const { refresh, refreshChatList, setRefreshChatList } = useMessaging()
  const history = useHistory()
  const toast = useToast()
  const [loading, setLoading] = useState(true)
  const [chats, setChats] = useState<any>([])
  const filteredChats = useMemo(() => {
    return chats.map((c: any) => ({
      ...c,
      receiver: c.user?.id === b2bUser?.id ? c.shelf?.user?.alias : c.user?.alias,
      receiver_id: c.user?.id === b2bUser?.id ? c.shelf?.user?.id : c.user?.id
    }
    ))
  }, [chats])
  const pendingChats = useMemo(() => {
    return filteredChats.filter((c: any) =>
      [borrowStatuses.request_pending, borrowStatuses.sync_address_pending, borrowStatuses.sync_pending].includes(c.status)
    )
  }, [filteredChats])

  const activeChats = useMemo(() => {
    return filteredChats.filter((c: any) =>
      [borrowStatuses.active, borrowStatuses.extension_pending].includes(c.status)
    )
  }, [filteredChats])

  const archivedChats = useMemo(() => {
    return filteredChats.filter((c: any) =>
      [borrowStatuses.request_canceled, borrowStatuses.request_rejected, borrowStatuses.terminated].includes(c.status)
    )
  }, [filteredChats])

  let refreshEvent: any = null

  const loadChats = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get('borrows')
      setChats(data)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero delle chat")
    }

    refreshEvent?.detail.complete()
    refreshEvent = null

    setLoading(false)
  }

  const updateLastMessageTime = async () => {
    try {
      await axios.post('/user/update_last_message_time')
    } catch (error) {
      console.error(error)
      toast.error("Errore nell'aggiornamento dei messaggi non letti")
    }
  }

  const refreshChats = async (event: CustomEvent<RefresherEventDetail> | null = null) => {
    refreshEvent = event
    loadChats()
    updateLastMessageTime()
  }

  useEffect(() => {
    if (user && token) {
      loadChats()
      updateLastMessageTime()
    }
  }, [user, token])

  useEffect(() => {
    if (refresh || refreshChatList) {
      refreshChats()
      setRefreshChatList(false)
    }
  }, [refresh, refreshChatList])

  return (
    <DefaultLayout
      toolbar={
        <IonToolbar class="light-toolbar">
          <IonTitle style={{ textTransform: "uppercase", fontSize: 16 }}>
            Prestiti
          </IonTitle>
        </IonToolbar>
      }
      loading={loading}
    >
      <IonRefresher slot="fixed" onIonRefresh={refreshChats}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>

      {(activeChats.length === 0 && archivedChats.length === 0 && pendingChats.length === 0 && !loading) &&
        <IonGrid>
          <IonRow>
            <IonCol size="12" class="ion-text-center">
              <IonText>
                Nessuna chat sospesa, in corso o archiviata
              </IonText>
            </IonCol>
          </IonRow>

          <IonRow class="ion-justify-content-center ion-margin-top">
            <IonCol size="8">
              <IonImg src="/images/empty-chat.svg" />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      {pendingChats.length !== 0 &&
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText class="ion-padding">In Sospeso</IonText>
            </IonCol>
          </IonRow>
          {
            pendingChats.map((chat: any, i: number) => {
              return <ChatCard
                key={i}
                chat={chat}
                onClick={() => history.push(`/chats/${chat.id}`)}
              />
            })
          }
        </IonGrid>
      }
      {activeChats.length !== 0 && pendingChats.length !== 0 &&
        <hr style={{ backgroundColor: "#c0c0c0", margin: 10, height: 3 }} />
      }
      {activeChats.length !== 0 &&
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText class="ion-padding">In Corso</IonText>
            </IonCol>
          </IonRow>
          {
            activeChats.map((chat: any, i: number) => {
              return <ChatCard
                key={i}
                chat={chat}
                onClick={() => history.push(`/chats/${chat.id}`)}
              />
            })
          }
        </IonGrid>
      }
      {activeChats.length !== 0 && archivedChats.length !== 0 &&
        <hr style={{ backgroundColor: "#c0c0c0", margin: '10px 12px', height: 3 }} />
      }
      {archivedChats.length !== 0 &&
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText class="ion-padding">Archivio</IonText>
            </IonCol>
          </IonRow>
          {
            archivedChats.map((chat: any, i: number) => {
              return <ChatCard
                key={i}
                chat={chat}
                onClick={() => history.push(`/chats/${chat.id}`)}
              />
            })
          }
        </IonGrid>
      }
    </DefaultLayout>
  )
}

export default ChatList