import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonImg,
  IonRow
} from "@ionic/react"
import { useHistory } from "react-router"
import EmptyLayout from "../layout/EmptyLayout"
import { useAuth } from "../providers/AuthProvider"

const Error: React.FC = () => {
  const { userError, refreshB2bUser } = useAuth()

  const history: any = useHistory()

  const retry = async () => {
    history.replace('/')
    await refreshB2bUser()
  }

  const imgStyle = {
    width: '50%',
    margin: '16px auto auto auto'
  }

  const titleStyle = {
    margin: 20
  }

  const errorCard = {
    background: 'rgba(255, 0, 0, 0.07)',
    color: 'red',
    marginBottom: 40
  }

  return (
    <EmptyLayout>
      <IonImg src="/images/logo_hori_600x200.png" style={imgStyle} />
      <h5 style={titleStyle}><strong>Leggi, presta, incontra.</strong></h5>
      <IonRow style={{ marginTop: '15%' }}>
        <IonCol size="12" class="ion-padding-vertical">
          <IonImg src="/images/error.svg" class="ion-justify-content-center ion-margin" style={{ marginBottom: 40 }} />

          {userError &&
            <IonCard style={errorCard}>
              <IonCardHeader>
                <IonCardTitle style={{ color: 'red', fontSize: 18 }}>Errore</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                {userError}
              </IonCardContent>
            </IonCard>
          }
          <IonButton expand="block" onClick={retry}>Riprova</IonButton>
        </IonCol>
      </IonRow>
    </EmptyLayout >
  )
}

export default Error