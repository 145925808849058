
import { StatusBar, Style } from "@capacitor/status-bar"
import {
  IonContent,
  IonHeader,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react"
import { PropsWithChildren, ReactNode } from "react"
import Loading from "../components/Loading"
import { useTabs } from "../components/Main"
import { Capacitor } from "@capacitor/core"
import { useDevice } from "../providers/DeviceProvider"

interface DefaultLayoutProps {
  toolbar: ReactNode
  loading?: boolean
}

const DefaultLayout: React.FC<PropsWithChildren<DefaultLayoutProps>> = ({ children, toolbar, loading }) => {

  const { setShowTabs } = useTabs()
  const { isAndroid, statusBarHeight } = useDevice()

  useIonViewDidEnter(() => {
    setShowTabs(true)

    try {
      if (Capacitor.isPluginAvailable('StatusBar')){
        StatusBar.setStyle({ style: Style.Light })
        StatusBar.setBackgroundColor({color: '#FFFFFF'})
      }   
    } catch (error) { }
  })

  const headerStyle = {
    paddingTop: 0
    //paddingTop: isAndroid ? statusBarHeight : ''
  }

  const contentStyle = {
    '--padding-bottom': 'calc(env(safe-area-inset-bottom) + 50px)'
  }

  return (
    <IonPage>
      <IonHeader style={headerStyle}>
        {toolbar}
      </IonHeader>
      <Loading isLoading={loading} />
      <IonContent style={contentStyle}>
        {children}
      </IonContent>
    </IonPage>
  )
}

export default DefaultLayout