import { Browser } from "@capacitor/browser"
import {
  IonIcon,
  IonButton,
  IonList,
  IonTitle,
  IonBackButton,
  IonButtons,
} from "@ionic/react"
import axios from "axios"
import { chevronForwardOutline } from "ionicons/icons"
import SecondaryToolbar from "../components/SecondaryToolbar"
import SecondaryLayout from "../layout/SecondaryLayout"

type Tutorial = {
  text: string,
  link: string,
  icon?: string,
}

const Settings: React.FC = () => {


  const tutorials: Tutorial[] = [
    {
      text: "Linee Guida",
      link: `${axios.defaults.baseURL}/guide_lines`
    },
    {
      text: "Cos’è Book2Book?",
      link: "https://youtube.com/shorts/Rty2jqjAdLY?feature=share",
      icon: chevronForwardOutline
    },
    {
      text: "Cosa sono i BookPoint?",
      link: "https://youtube.com/shorts/Jt0jvDBQ5ns?feature=share",
      icon: chevronForwardOutline
    },
    {
      text: "Come aggiungere un libro?",
      link: "https://youtu.be/_1-6P5Fsc2A",
      icon: chevronForwardOutline
    },
    {
      text: "La mia libreria!",
      link: "https://youtu.be/sEl4R7Uah8A",
      icon: chevronForwardOutline
    },
    {
      text: "Come chiedere il prestito di un libro?",
      link: "https://youtu.be/0Z-3pvoz738",
      icon: chevronForwardOutline
    },
    {
      text: "Come prestare un libro?",
      link: "https://youtu.be/_YEzHgUfKAU",
      icon: chevronForwardOutline
    },
    {
      text: "Cos’è l’ISBN?",
      link: "https://youtube.com/shorts/RZVrkf40FeE?feature=share",
      icon: chevronForwardOutline
    }
  ]

  const openLink = async ({ link }: { link: string }) => {
    await Browser.open({ url: link });
  }

  return (
    <SecondaryLayout
      toolbar={
        <SecondaryToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" color="light" />
          </IonButtons>
          <IonTitle>
            Tutorial
          </IonTitle>
        </SecondaryToolbar>
      }
      showTabs
    >
      <IonList>
        {
          tutorials.map((t: Tutorial, x: number) =>
            <IonButton
              key={x}
              color="secondary"
              class="ion-margin-vertical"
              shape="round"
              expand="full"
              onClick={async () => await openLink(t)}
            >
              {t.text}
              {t.icon &&
                <IonIcon slot="end" icon={t.icon} />
              }
            </IonButton>
          )
        }
      </IonList>
    </SecondaryLayout>
  )
}

export default Settings