import { useEffect } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router";
import { useAuth } from "../providers/AuthProvider";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const history = useHistory();
  const { user } = useAuth()

  useEffect(() => {
    if (!user) {
      // no user logged in
      history.push('/login', { loginModalOpen: true })
    }
  }, [])

  return (<Route render={(props: any) =>
    <Component {...props} />
  }
    {...rest}
  />)
}

export default ProtectedRoute;