import {
  IonApp,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { ToastProvider } from './providers/ToastProvider';
import { FirebaseMessagingProvider } from './providers/FirebaseMessagingProvider';
import { AuthProvider } from './providers/AuthProvider';
import { DeviceProvider } from './providers/DeviceProvider';
import Main from './components/Main';

// import { Cookies } from 'react-cookie';
// import ReactGA from "react-ga4";
// import CookieBanner from 'react-cookie-banner';
// import { barChartOutline } from 'ionicons/icons';


setupIonicReact();


const App: React.FC = () => {
  // const cookies = new Cookies();

  // useEffect(() => {
  //   if (cookies.get("user-has-accepted-cookies")) {
  //     ReactGA.initialize("G-NDXE5QFD50");
  //   }
  // }, [cookies.get("user-has-accepted-cookies")])

  // const cookiesCardStyle = {
  //   width: "95%",
  //   margin: " auto auto 65px",
  //   maxWidth: 650,
  //   opacity: 0.9,
  //   color: "info",
  //   bottom: "env(safe-area-inset-bottom)"
  // }

  return (
    <IonApp>
      <IonReactRouter>
        <DeviceProvider>
          <ToastProvider value={{ duration: 3000 }}>
            <AuthProvider>
              <FirebaseMessagingProvider>
                <Main />
                {/*
                  <CookieBanner
                    onAccept={() => { ReactGA.initialize("G-NDXE5QFD50"); }}
                    cookie="user-has-accepted-cookies">
                      {(onAccept: any) => (
                        <IonCard color="info" style={cookiesCardStyle}>
                          <IonCardContent style={{ padding: "5px 10px", fontSize: 14, textAlign: "center" }} >
                            <IonIcon icon={barChartOutline} style={{ verticalAlign: "middle", fontSize: 20, paddingBottom: 2, paddingRight: 7 }} />
                            <IonText>Questo sito utilizza i cookie</IonText>
                            <IonButton style={{ position: "relative", marginLeft: "auto", marginRight: 0 }} size="small" color="success" onClick={onAccept}>Accetta</IonButton>
                          </IonCardContent>
                        </IonCard>
                    )}
                  </CookieBanner>
                */}
              </FirebaseMessagingProvider>
            </AuthProvider>
          </ToastProvider>
        </DeviceProvider>
      </IonReactRouter>
    </IonApp>
  )
}

export default App;
