import {
  IonGrid,
  IonText,
  IonModal,
  IonContent
} from '@ionic/react';
import { QRCodeCanvas } from 'qrcode.react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { borrowStatuses } from '../enums/BorrowStatuses'
import { useToast } from '../providers/ToastProvider';

interface QRCodeModalProps {
  isOpen: boolean
  borrow: any,
  isHost: boolean,
  onRead: (result: any) => void
  onDidDismiss: () => void
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ isOpen, borrow, isHost, onDidDismiss, onRead }) => {

  const toast = useToast()

  const renderQRModal = () => {
    if ((isHost && borrow.status === borrowStatuses.sync_pending) || (!isHost && borrow.status === borrowStatuses.active)) {
      return (
        <IonGrid>
          <BarcodeScannerComponent
            width="100%"
            onUpdate={(_, result: any) => {
              if (result)
                onRead(result.text)
            }}
            onError={(error: any) => {
              console.error(error)
              toast.error("Impossibile leggere il QRCode: " + error)
            }}
          />
        </IonGrid>
      )
    } else if ((isHost && borrow.status === borrowStatuses.active) || (!isHost && borrow.status === borrowStatuses.sync_pending)) {
      return (
        <IonText style={{ verticalAlign: "middle" }} onClick={onDidDismiss}>
          <QRCodeCanvas
            includeMargin
            value={borrow.qrcode}
            size={(window.innerWidth * 90) / 100}
          />
        </IonText>
      )
    }
  }

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      onClick={onDidDismiss}
      className="sync-borrow-modal"
    >
      <IonContent class="ion-text-center" scrollY={false}>
        {renderQRModal()}
      </IonContent>
    </IonModal>
  )
}

export default QRCodeModal