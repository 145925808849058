import {
  IonAvatar,
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonChip,
  IonCol,
  IonImg,
  IonLabel,
  IonRow
} from "@ionic/react"
import { borrowStatuses } from "../enums/BorrowStatuses"

interface ChatCardProps {
  chat: any
  onClick: () => void
}

const ChatCard: React.FC<ChatCardProps> = ({ chat, onClick }) => {

  const cardStyle = {
    background: [borrowStatuses.request_canceled, borrowStatuses.request_rejected, borrowStatuses.terminated].includes(chat.status) ? "#eaeaea" : "#ffded1",
    borderRadius: 17,
    padding: 7,
    overflow: 'initial',
    contain: 'initial'
  }

  switch (chat.status) {
    case borrowStatuses.request_pending:
    case borrowStatuses.sync_address_pending:
    case borrowStatuses.sync_pending:
      cardStyle.background = "rgba(255, 130, 67, 0.25)"
      break
    case borrowStatuses.active:
    case borrowStatuses.extension_pending:
      cardStyle.background = "rgba(255, 51, 112, 0.25)"
      break
  }

  const chipStyle = {
    fontSize: 12,
    padding: "1px 6px",
    height: 23
  }

  return (
    <IonCard style={cardStyle} onClick={onClick}>
      {!chat.readed &&
        <IonBadge color="primary" mode="ios" style={{ position: "absolute", right: -4, top: -4 }}>&nbsp;</IonBadge>
      }
      <IonChip style={{ ...chipStyle, ...{ minWidth: 100 } }}>
        <IonAvatar>
          <IonImg
            src={
              chat?.shelf?.user?.bookpoint ?
                chat?.shelf?.user?.image ?? "/images/bookpoint-no-picture.jpg"
                :
                `${process.env.REACT_APP_API_URL}/users/${chat.receiver_id}/avatar`
            }
          />
        </IonAvatar>
        <IonLabel>{chat.receiver}</IonLabel>
      </IonChip>
      {[borrowStatuses.active, borrowStatuses.extension_pending].includes(chat.status) &&
        <IonChip style={chipStyle} class="primary-chip">
          <IonLabel>{chat.days < 0 ? "Scaduto" : `- ${chat.days} gg`}</IonLabel>
        </IonChip>
      }

      <IonRow style={{ padding: 7 }}>
        <IonCol size="12" style={{ padding: 0 }}>
          <IonCardSubtitle>{chat.shelf?.book?.title}</IonCardSubtitle>
        </IonCol>
        <IonCol style={{ padding: 0 }}>

          <IonCard color="light-grey" style={{ margin: 0, borderRadius: "0px 10px 10px 10px" }}>
            <IonCardContent style={{ padding: "10px 13px", fontSize: 14 }} >
              {chat.last_message}
            </IonCardContent>
          </IonCard>


          {/* <IonText color="dark" style={{ textTransform: 'none', fontSize: 12 }}>
            {chat.last_message}
          </IonText> */}
        </IonCol>
      </IonRow>
      {/*
      <IonRow>
        <div className="ion-text-end">
          <small>{ format(new Date(chat.last_message_at), "dd/MM/yyyy HH:mm:ss") }</small>
        </div>
      </IonRow> */}
    </IonCard>
  )
}

export default ChatCard