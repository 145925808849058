import {
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  useIonAlert,
  useIonViewDidEnter,
  useIonViewDidLeave
} from "@ionic/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { borrowStatuses } from '../enums/BorrowStatuses'
import { useToast } from "../providers/ToastProvider";
import Loading from "./Loading";
import BookPointSelectionModal from "./BookPointSelectionModal";
import QRCodeModal from "./QrCodeModal";
import { useDevice } from "../providers/DeviceProvider";
import { useHistory } from "react-router";

interface PinnedMessageProps {
  borrow: any,
  qrCodeResult: any
  isHost: boolean,
  onUpdate: () => void
  resetQrCodeResult: () => void
  router?: HTMLIonRouterOutletElement | null;
}

const PinnedMessage: React.FC<PinnedMessageProps> = ({ borrow, qrCodeResult, isHost, onUpdate, resetQrCodeResult }) => {

  const [loading, setLoading] = useState(false)
  const [showQRModal, setShowQRModal] = useState(false)
  const [showBookpointModal, setShowBookpointModal] = useState(false)
  const [present] = useIonAlert()
  const [borrowInterval, setBorrowInterval] = useState<any>(null)
  const toast = useToast()
  const { isWeb } = useDevice()
  const history: any = useHistory()

  const acceptBorrow = async () => {
    setLoading(true)

    try {
      await axios.post(`/borrows/${borrow.id}/accept`)
      onUpdate()
    } catch (error) {
      console.error(error)
      toast.error("Errore nell'accettazione del prestito")
    }

    setLoading(false)
  }

  const rejectBorrow = async () => {
    setLoading(true)

    try {
      await axios.post(`/borrows/${borrow.id}/reject`)
      onUpdate()
    } catch (error) {
      console.error(error)
      toast.error("Errore nel rifiuto del prestito")
    }

    setLoading(false)
  }

  const syncAddress = async (addressToSync: any) => {
    setShowBookpointModal(false)
    setLoading(true)

    try {
      await axios.post(`/borrows/${borrow.id}/sync_address`, addressToSync)
      onUpdate()
    } catch (error) {
      console.error(error)
      toast.error("Errore nel salvataggio dell'indirizzo")
    }

    setLoading(false)
  }

  const qrCodeRead = async (result: any) => {

    setShowQRModal(false)

    let message = null

    if (borrow.status === borrowStatuses.sync_pending) {
      message = "Confermi l'avvio del prestito?"
    } else if (borrow.status === borrowStatuses.active) {
      message = "Confermi il termine del prestito?"
    }
    if (message) {
      await present({
        header: message,
        buttons: [
          'Indietro',
          {
            text: 'Ok', handler: async () => {
              if (borrow.status === borrowStatuses.sync_pending) {
                await startBorrow(result)
              } else if (borrow.status === borrowStatuses.active) {
                await terminateBorrow(result)
              }
            }
          },
        ]
      })
    }
    return !!message
  }

  const startBorrow = async (qrcode: string) => {
    setLoading(true)

    try {
      await axios.post(`/borrows/${borrow.id}/sync`, { qrcode })
      onUpdate()
    } catch (error: any) {
      console.error(error)
      toast.error("Errore nell'avviamento della prenotazione: " + error.response.data)
    }

    setLoading(false)
  }

  const rejectExtension = async () => {
    setLoading(true)

    try {
      await axios.post(`/borrows/${borrow.id}/extension_rejected`)
      onUpdate()
    } catch (error) {
      console.error(error)
      toast.error("Errore durante il rifiuto della richiesta di estensione")
    }

    setLoading(false)
  }


  const acceptExtension = async () => {
    setLoading(true)

    try {
      await axios.post(`/borrows/${borrow.id}/extension_accepted`)
      onUpdate()
    } catch (error) {
      console.error(error)
      toast.error("Errore durante l'accettazione della richiesta di estensione")
    }

    setLoading(false)
  }

  const terminateBorrow = async (qrcode: string) => {
    setLoading(true)

    try {
      await axios.post(`/borrows/${borrow.id}/terminate`, { qrcode })
      toast.success("Prenotazione terminata con successo")
      onUpdate()
    } catch (error) {
      console.error(error)
      toast.error("Errore durante la chiusura del prestito")
    }

    setLoading(false)
  }

  const openScan = async () => {
    if (isWeb) {
      setShowQRModal(true)
    } else {
      history.push('/scan_qr', { from: history.location.pathname })
    }
  }

  useEffect(() => {
    if (showQRModal) {
      setBorrowInterval(setInterval(async () => {
        try {
          const { data } = await axios.get(`borrows/${borrow.id}`)
          if ((borrow.status === borrowStatuses.sync_pending && data.status === borrowStatuses.active) ||
            (borrow.status === borrowStatuses.active && data.status === borrowStatuses.terminated)) {
            setShowQRModal(false)
            onUpdate()
          }
        } catch (error) {
          console.error(error)
          toast.error("Errore nel caricamento della chat")
        }
      }, 3000))
    } else {
      clearInterval(borrowInterval)
    }
  }, [showQRModal])

  useEffect(() => {
    if (qrCodeResult) {
      qrCodeRead(qrCodeResult)
      resetQrCodeResult()
    }
  }, [qrCodeResult])

  useIonViewDidLeave(() => {
    setShowQRModal(false)
    clearInterval(borrowInterval)
  })

  const textStyle = {
    fontSize: 13
  }

  const renderPinnedMessage = () => {
    switch (borrow.status) {
      case borrowStatuses.request_pending:
        if (isHost) {
          return (
            <IonRow style={{ padding: 4 }}>
              <IonCol size="12">
                <IonText style={textStyle}>
                  <strong color="secondary">{borrow.user.alias}</strong> ha richiesto <strong>{borrow.shelf?.book.title}</strong> in prestito.</IonText>
              </IonCol>
              <IonCol>
                <IonButton shape="round" size="small" expand='block' onClick={acceptBorrow}>
                  Accetta
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton shape="round" size="small" expand='block' onClick={rejectBorrow}>
                  Rifiuta
                </IonButton>
              </IonCol>
            </IonRow >
          )
        } else {
          return (
            <IonRow>
              <IonCol>
                <IonText style={textStyle}>La tua richiesta è stata inviata al proprietario del libro!</IonText>
              </IonCol>
            </IonRow >
          )
        }

      case borrowStatuses.sync_address_pending:
        if (isHost) {
          return (
            <IonRow class="ion-align-items-center">
              <IonCol>
                <IonText style={textStyle}>
                  Scegli il luogo di incontro
                </IonText>
              </IonCol>
              <IonCol>
                <IonButton shape="round" size="small" expand='block' onClick={() => setShowBookpointModal(true)}>
                  Seleziona un BookPoint
                </IonButton>
              </IonCol>
            </IonRow>
          )
        } else {
          return (
            <IonRow>
              <IonCol>
                <IonText style={textStyle}>La richiesta è stata accettata. L'host sta selezionando il punto di incontro.</IonText>
              </IonCol>
            </IonRow>
          )
        }

      case borrowStatuses.sync_pending:
        if (isHost) {
          return (
            <IonRow >
              <IonCol size="12">
                <IonText style={textStyle}>
                  Per confermare clicca e inquadra il QR di {borrow.user.alias}
                </IonText>
              </IonCol>
              <IonCol size="12">
                <IonButton shape="round" size="small" expand='block' onClick={openScan}>
                  Conferma Prenotazione
                </IonButton>
              </IonCol>
            </IonRow>
          )
        } else {
          return (
            <IonRow>
              <IonCol size="12" class="ion-justify-content-center">
                <IonText style={textStyle}>
                  Per avviare il prestito chiedi all'host di scansionare il QR.
                </IonText>
              </IonCol>
              <IonCol size="12">
                <IonButton shape="round" size="small" expand='block' onClick={() => setShowQRModal(true)}>
                  Visualizza QR
                </IonButton>
              </IonCol>
            </IonRow>
          )
        }

      case borrowStatuses.active:
        if (isHost) {
          return (
            <IonRow>
              <IonCol size="12">
                <IonText style={textStyle}>
                  Per terminare il prestito far scansionare il codice QR a {borrow.user.alias}
                </IonText>
              </IonCol>
              <IonCol size="12">
                <IonButton shape="round" size="small" expand='block' onClick={() => setShowQRModal(true)}>
                  Visualizza QRCode
                </IonButton>
              </IonCol>
            </IonRow>
          )
        } else {
          return (
            <IonRow>
              <IonCol size="12">
                <IonText style={textStyle}>
                  Per terminare il prestito clicca il pulsante e inquadra il QR dell'host
                </IonText>
              </IonCol>
              <IonCol size="12">
                <IonButton shape="round" size="small" expand='block' onClick={openScan}>
                  Termina il prestito
                </IonButton>
              </IonCol>
            </IonRow>
          )
        }

      case borrowStatuses.extension_pending:
        if ((isHost && borrow.extension_from === "host") || (!isHost && borrow.extension_from === "guest")) {
          return (
            <IonRow>
              <IonCol>
                <IonText style={textStyle}>La tua richiesta di estensione è stata inviata. Attendi...</IonText>
              </IonCol>
            </IonRow>
          )
        } else {
          return (
            <IonRow >
              <IonCol size="12">
                <IonText style={textStyle}>
                  {borrow.extension_from === "host" ? borrow.shelf.user.alias : borrow.user.alias}
                  ti ha richiesto un prolungamento di 15 giorni per il prestito
                </IonText>
              </IonCol>
              <IonCol>
                <IonButton shape="round" size="small" expand='block' onClick={acceptExtension}>
                  Accetta
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton shape="round" size="small" expand='block' onClick={rejectExtension}>
                  Rifiuta
                </IonButton>
              </IonCol>
            </IonRow >
          )
        }

      default:
        return null
    }
  }

  return (
    <>
      {![borrowStatuses.request_canceled, borrowStatuses.request_rejected, borrowStatuses.terminated].includes(borrow.status) &&
        <IonGrid class="ion-no-padding ion-text-center">
          <Loading isLoading={loading} />
          {renderPinnedMessage()}
        </IonGrid >
      }
      <BookPointSelectionModal
        isOpen={showBookpointModal}
        onDidDismiss={() => setShowBookpointModal(false)}
        onSaveSelection={syncAddress}
      />
      <QRCodeModal
        isOpen={showQRModal}
        borrow={borrow}
        isHost={isHost}
        onDidDismiss={() => { setShowQRModal(false) }}
        onRead={qrCodeRead}
      />
    </>
  )
}

export default PinnedMessage