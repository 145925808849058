import {
  IonButton,
  IonCol,
  IonGrid,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
  RefresherEventDetail,
} from "@ionic/react"
import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import BookPointCard, { BookPoint } from "../components/BookPointCard"
import { useToast } from "../providers/ToastProvider"
import { Geolocation, Position } from '@capacitor/geolocation';
import { bookpointsWithDistance } from '../plugins/distance'
import DefaultLayout from "../layout/DefaultLayout"
import BookPointToolbar from "../components/BookPointToolbar"
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import L from 'leaflet';
import Dot from "../components/Dot"

export type BookPointFilterParams = {
  opened: number,
  has_shelfs: number
}

const BookPoints: React.FC = () => {
  const defaultFilter: BookPointFilterParams = {
    opened: 0,
    has_shelfs: 0
  }

  const [loading, setLoading] = useState(false)
  const [bookPoints, setBookPoints] = useState<BookPoint[]>([])
  const [filter, setFilter] = useState(defaultFilter)
  const [selectedSegment, setSelectedSegment] = useState<string | undefined>('list')
  const [position, setPosition] = useState<Position | null | undefined>(undefined)
  const [mapCenter, setMapCenter] = useState<any>([45.04897253794322, 7.65974521636963])
  const [mapZoom, setMapZoom] = useState<number>(12)
  const filteredBookPoints = useMemo(() => {
    return bookpointsWithDistance(bookPoints, position)
      .filter(bookPoint =>
        (filter?.has_shelfs ? bookPoint.has_shelfs : true) && (filter?.opened ? bookPoint.open : true)
      )
  }, [bookPoints, filter, position])
  const [search, setSearch] = useState("")
  const history = useHistory()
  const toast = useToast()
  const markerIcon = new L.Icon({
    iconUrl: require('../img/marker-icon.png'),
    iconRetinaUrl: require('../img/marker-icon.png'),
    iconSize: new L.Point(30, 30, true),
    className: 'leaflet-div-icon'
  });
  const userIcon = new L.Icon({
    iconUrl: require('../img/user-position-icon.png'),
    iconRetinaUrl: require('../img/user-position-icon.png'),
    iconSize: new L.Point(30, 30, true),
    className: 'leaflet-div-icon'
  });

  const loadBookPoints = async () => {
    setLoading(true)

    try {
      const { data: bookPoints } = await axios.get("bookpoints", { params: { search } })

      setBookPoints(bookPoints)

    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero dei bookpoint")
    }

    setLoading(false)
  }

  const setCurrentPosition = async () => {
    try {
      setPosition(await Geolocation.getCurrentPosition());
    } catch (error: any) {
      // 1 -> permission denied => l'utente non ha accettato di condividere la posizione
      if (error.code !== 1) {
        console.error(error)
        toast.error("Errore nel recupero della posizione")
      }

      setPosition(null);
    }
  };

  const distance = (d: number) => {
    let ret = 'a '
    ret += d >= 1000 ?
      (Number((d / 1000).toFixed(1))) + ' km'
      :
      d + ' m'
    ret += ' da te'
    return ret
  }

  const availableShelfs = (stats: any) => {
    let ret = ''
    if (stats.shelfs === 0 && stats.borrows === 0) return 'Nessun Libro disponibile'
    ret = `${stats.shelfs} ${stats.shelfs === 1 ? 'Libro disponibile' : 'Libri disponibili'}`
    if (stats.borrows !== 0)
      ret += `, ${stats.borrows} ${stats.borrows === 1 ? 'Scambio avvenuto' : 'Scambi avvenuti'}`
    return ret
  }

  const refreshBookPoints = async (event: CustomEvent<RefresherEventDetail>) => {
    await loadBookPoints()
    event.detail.complete()
  }

  const MapInnerComponent = () => {
    const map = useMap()
    useMapEvents({
      moveend: () => {
        const center = map.getCenter()
        setMapCenter([center.lat, center.lng])
        setMapZoom(map.getZoom())
      }
    })
    return null
  }

  useEffect(() => {
    loadBookPoints()
  }, [search])

  useEffect(() => {
    if (!position)
      setCurrentPosition()
  }, [position])

  return (
    <DefaultLayout
      toolbar={
        <BookPointToolbar
          {...{ search, setSearch, filter, setFilter, selectedSegment, setSelectedSegment }}
        />
      }
      loading={loading}
    >
      {selectedSegment === 'list' ?
        <>
          <IonRefresher slot="fixed" onIonRefresh={refreshBookPoints}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonGrid style={{ paddingTop: 10 }}>
            {
              filteredBookPoints.map((bookPoint: BookPoint, i: number) =>
                <BookPointCard
                  key={i}
                  bookPoint={bookPoint}
                  onClick={() => history.push(`/bookPoints/${bookPoint.id}`)}
                />
              )
            }
          </IonGrid>
        </>
        :
        <div style={{ height: '89vh', width: '100%' }}>
          <MapContainer
            center={mapCenter}
            zoom={mapZoom}
            attributionControl={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
              filteredBookPoints.map((b: any, i: number) =>
                <Marker
                  key={i}
                  position={[Number(b.latitude), Number(b.longitude)]}
                  icon={markerIcon}
                >
                  <Popup minWidth={300}>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonText style={{ fontSize: 15 }}>
                            <strong>
                              {b.full_name}
                            </strong>
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        {b.address &&
                          <IonCol>
                            <IonText>
                              {b.address}
                            </IonText>
                          </IonCol>
                        }
                      </IonRow>
                      {b.distance &&
                        <IonRow>
                          <IonCol >
                            <IonText>
                              {distance(b.distance)}
                            </IonText>
                          </IonCol>
                        </IonRow>
                      }
                      <IonRow>
                        <IonCol size="9">
                          <IonText>
                            {
                              b.current_timetable ?
                                `${b.current_timetable?.label} ${b.current_timetable?.start} - ${b.current_timetable?.end} `
                                :
                                "Oggi Chiuso"
                            }
                          </IonText>
                        </IonCol>
                        <IonCol size="3">
                          <Dot open={b.open} />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol >
                          <IonText>
                            {availableShelfs(b.stats)}
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonButton
                      size="small"
                      expand='block'
                      onClick={() => history.push(`/bookPoints/${b.id}`)}
                    >
                      Altre info
                    </IonButton>
                  </Popup>
                </Marker>
              )
            }
            {position &&
              <Marker
                position={[position.coords.latitude, position.coords.longitude]}
                icon={userIcon}
              >
              </Marker>
            }
            <MapInnerComponent />
          </MapContainer>
        </div>
      }
    </DefaultLayout>
  )
}

export default BookPoints