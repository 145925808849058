import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonContent,
  IonGrid,
  IonCard,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from "@ionic/react"
import axios from "axios"
import { closeOutline } from "ionicons/icons"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { useDevice } from "../providers/DeviceProvider"
import { useToast } from "../providers/ToastProvider"
import BookCard from "./BookCard"
import { BookPoint } from "./BookPointCard"

interface BookpointShelfsModalProps {
  isOpen: boolean
  onDidDismiss: () => void
  bookPoint: BookPoint
}

const BookpointShelfsModal: React.FC<BookpointShelfsModalProps> = ({ isOpen, onDidDismiss, bookPoint }) => {

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)
  const [bookPointShelfs, setBookPointShelfs] = useState<any[]>([])
  const [page, setPage] = useState({ page: 1 })
  const [infiniteEvent, setInfiniteEvent] = useState<any>()
  const shelfsModalRef: any = useRef()
  const history = useHistory()
  const toast = useToast()
  const { isAndroid } = useDevice()

  const loadBookPointShelfs = async () => {
    try {
      const { data, meta }: any = await axios.get(`/users/${bookPoint.id}/shelfs`, { params: { page: page.page } })
      setBookPointShelfs([...bookPointShelfs, ...data])
      setDisableInfiniteScroll(data.length < meta.limit || data.length === 0)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero dei libri del BookPoint")
    }

    infiniteEvent?.target?.complete()
  }

  const loadMoreBookPointsShelfs = (event: any) => {
    setInfiniteEvent(event)
    setPage({ page: page.page + 1 })
  }

  useEffect(() => {
    if (bookPoint.id)
      loadBookPointShelfs()
  }, [page, bookPoint])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        shelfsModalRef && shelfsModalRef.current && (shelfsModalRef.current.ontouchmove = function (e: any) {
          e.stopPropagation();
        })
      }, 0)
    }
  }, [isOpen])

  const backIconStyle = {
    fontSize: 20,
    padding: 10
  }

  const noBookCardStyle = {
    width: "70%",
    margin: "auto",
    color: "secondary",
    padding: 10
  }

  const infoCardStyle = {
    color: "info",
    margin: 13,
    padding: 13
  }

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      initialBreakpoint={isAndroid ? 0.95 : 0.99}
      breakpoints={[0, isAndroid ? 0.95 : 0.99]}
      backdropBreakpoint={0.75}
    >
      <IonHeader>
        <IonToolbar class="light-toolbar">
          <IonTitle>Libri Disponibili</IonTitle>
          <IonIcon
            style={backIconStyle}
            slot="end"
            icon={closeOutline}
            onClick={onDidDismiss}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent ref={shelfsModalRef}>
        <IonGrid>
          {bookPointShelfs.length === 0 &&
            <IonCard class="ion-text-center" color="light-secondary" style={noBookCardStyle}>
              Nessun Libro Disponibile presso questo Bookpoint
            </IonCard>
          }
          {
            bookPointShelfs.length !== 0 && bookPoint.borrows_timetables &&
            <IonCard color="info" style={infoCardStyle}>
              <IonText>Puoi recarti presso questo Book Point per ritirare il tuo prestito nei seguenti orari:</IonText>
              <br />
              <ul>
                {
                  bookPoint.borrows_timetables?.map((t: any, i: number) =>
                    <li style={{ fontWeight: "bold" }} key={i}>{t.days} {t.hours}</li>
                  )
                }
              </ul>
              <IonText dangerouslySetInnerHTML={{ __html: bookPoint.extra_info }} style={{ fontSize: 12 }} />
              <br />
              <IonText style={{ fontSize: 12 }}>
                La struttura rimane comunque disponibile negli orari di apertura per lo scambio tra utenti
              </IonText>
            </IonCard>
          }
          {
            bookPointShelfs.map((s: any, x: number) =>
              <BookCard key={x} book={s.book} onClick={() => {
                onDidDismiss()
                history.push(`/myShelf/${s.id}`)
              }} />
            )
          }
        </IonGrid>
        <IonInfiniteScroll
          threshold="1%"
          onIonInfinite={loadMoreBookPointsShelfs}
          disabled={disableInfiniteScroll}
        >
          <IonInfiniteScrollContent
            loadingSpinner="dots"
            loadingText="Caricamento in corso..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonModal>
  )
}

export default BookpointShelfsModal