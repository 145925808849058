
import { IonToolbar } from "@ionic/react"
import { useDevice } from "../providers/DeviceProvider"

const SecondaryToolbar: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  // const { isAndroid, statusBarHeight } = useDevice()

  const toolbarStyle = {
   // paddingTop: isAndroid ? statusBarHeight : ''
  }

  return (
    <IonToolbar className={className} style={toolbarStyle}>
      {children}
    </IonToolbar>
  )
}

export default SecondaryToolbar