import { IonProgressBar } from "@ionic/react"

interface LoadingProps {
  isLoading: boolean | undefined
}

const Loading: React.FC<LoadingProps> = ({ isLoading }) => {

  return (
    <>
      {isLoading &&
        <IonProgressBar
          type="indeterminate"
        />
      }
    </>
  )
}

export default Loading