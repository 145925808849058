import { IonCol, IonImg, IonRow, IonSpinner, useIonViewDidEnter } from "@ionic/react"
import { useHistory } from "react-router"
import EmptyLayout from "../layout/EmptyLayout"
import useLocalStorageState from "use-local-storage-state"

const Auth: React.FC = () => {
  const history: any = useHistory()
  const [token] = useLocalStorageState<String | undefined>('token', {
    defaultValue: ""
  })

  useIonViewDidEnter(() => {
    if(token) {
      history.replace('/home')  
    } else {
      history.replace('/login')
    }
  })

  const imgStyle = {
    width: '50%',
    margin: '16px auto auto auto'
  }

  const titleStyle = {
    margin: 20
  }

  return (
    <EmptyLayout>
      <IonImg src="/images/logo_hori_600x200.png" style={imgStyle} />
      <h5 style={titleStyle}><strong>Leggi, presta, incontra.</strong></h5>
      <IonRow style={{ marginTop: '60%' }}>
        <IonCol size="12">
          <IonSpinner color="primary" style={{ height: 100, width: 100 }} />
        </IonCol>
      </IonRow>
    </EmptyLayout >
  )
}

export default Auth