import {
  IonToolbar,
  IonTitle,
  IonIcon,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSearchbar
} from "@ionic/react"
import {
  funnel,
  funnelOutline,
  lockOpen,
  lockClosed,
  chevronForwardOutline,
  chevronBackOutline,
  filterOutline
} from "ionicons/icons"
import { Dispatch, SetStateAction } from "react"
import { ShelfFilterParams } from "../pages/MyShelf"

interface ShelfToolbarProps {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  params: any
  setParams: Dispatch<SetStateAction<ShelfFilterParams>>
}

const ShelfToolbar: React.FC<ShelfToolbarProps> = ({ setSearch, params, setParams }) => {

  const hasFilterSelected = () => {
    return params.statuses.public === 1 || params.statuses.private === 1 || params.statuses.loaned === 1 || params.statuses.borrowed === 1
  }

  const iconStyle = {
    fontSize: 22
  }

  return (
    <>
      <IonToolbar class="light-toolbar">
        <IonTitle style={{ textTransform: "uppercase", fontSize: 16 }}>
          La mia Libreria
        </IonTitle>
        <IonIcon
          id="trigger-filter-myShelf"
          style={iconStyle}
          class="ion-padding"
          color={hasFilterSelected() ? 'primary' : 'grey'}
          slot="end"
          icon={hasFilterSelected() ? funnel : funnelOutline}
        />
        <IonPopover trigger="trigger-filter-myShelf">
          <IonContent class="ion-text-center" scrollY={false}>
            <IonList>
              <IonItem>
                <IonIcon style={{ verticalAlign: "middle", fontSize: 20 }} color="primary" icon={lockOpen} />
                <IonLabel>
                  <small>Pubblici</small>
                </IonLabel>
                <IonCheckbox
                  checked={!!params.statuses.public}
                  slot="end"
                  color="primary"
                  onIonChange={(e) => { setParams({ ...params, statuses: { ...params.statuses, public: e.detail.checked ? 1 : 0 } }) }}
                />
                {!!params.statuses.public}
              </IonItem>
              <IonItem>
                <IonIcon style={{ verticalAlign: "middle", fontSize: 20 }} color="secondary" icon={lockClosed} />
                <IonLabel>
                  <small>Privati</small>
                </IonLabel>
                <IonCheckbox
                  checked={!!params.statuses.private}
                  slot="end"
                  color="primary"
                  onIonChange={(e) => { setParams({ ...params, statuses: { ...params.statuses, private: e.detail.checked ? 1 : 0 } }) }}
                />
              </IonItem>
              <IonItem>
                <IonIcon style={{ verticalAlign: "middle", fontSize: 20 }} icon={chevronForwardOutline} />
                <IonLabel>
                  <small>Dati in Prestito</small>
                </IonLabel>
                <IonCheckbox
                  checked={!!params.statuses.loaned}
                  slot="end"
                  color="primary"
                  onIonChange={(e) => { setParams({ ...params, statuses: { ...params.statuses, loaned: e.detail.checked ? 1 : 0 } }) }}
                />
              </IonItem>
              <IonItem>
                <IonIcon style={{ verticalAlign: "middle", fontSize: 20 }} icon={chevronBackOutline} />
                <IonLabel>
                  <small>Presi in Prestito</small>
                </IonLabel>
                <IonCheckbox
                  checked={!!params.statuses.borrowed}
                  slot="end"
                  color="primary"
                  onIonChange={(e) => { setParams({ ...params, statuses: { ...params.statuses, borrowed: e.detail.checked ? 1 : 0 } }) }}
                />
              </IonItem>
            </IonList>
          </IonContent>
        </IonPopover>
        <IonIcon
          style={{ ...iconStyle, ...((params.order === "asc" || params.order === undefined) && { transform: "rotate(180deg)" }) }}
          class="ion-padding"
          color="primary"
          slot="end"
          icon={filterOutline}
          onClick={() => { setParams({ ...params, order: params.order === "desc" ? "asc" : "desc" }) }}
        />
      </IonToolbar>
      <IonToolbar class="light-toolbar">
        <IonSearchbar
          placeholder="Cerca nella tua libreria"
          debounce={750}
          onIonChange={e => {
            setSearch(e.detail.value!)
          }}
        />
      </IonToolbar>
    </>
  )
}

export default ShelfToolbar