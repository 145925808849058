import {
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
  useIonAlert,
  useIonViewDidEnter,
} from "@ionic/react"
import axios from "axios"
import { format, isToday } from "date-fns"
import { send } from "ionicons/icons"
import { useEffect, useMemo, useRef, useState } from "react"
import { RouteComponentProps, useHistory, withRouter } from "react-router"
import ChatRow from "../../components/ChatRow"
import PinnedMessage from "../../components/PinnedMessage"
import useInput from "../../hooks/useInput"
import { useToast } from "../../providers/ToastProvider"
import React from 'react'
import ChatOption from "../../components/ChatOption"
import { borrowStatuses } from "../../enums/BorrowStatuses"
import { onMessage } from "firebase/messaging";
import { messageStatuses } from "../../enums/MessageStatuses"
import { useAuth } from "../../providers/AuthProvider"
import { useMessaging } from "../../providers/FirebaseMessagingProvider"
import SecondaryLayout from "../../layout/SecondaryLayout"
import { useDevice } from "../../providers/DeviceProvider"
import ChatToolbar from "../../components/ChatToolbar"

interface Props extends RouteComponentProps {
  router?: HTMLIonRouterOutletElement | null;
}

const Chat: React.FC<Props> = ({ router, match }) => {
  const { user, b2bUser, token } = useAuth()
  const [loading, setLoading] = useState(true)
  const [sendMessageLoading, setSendMessageLoading] = useState(false)
  const [options, setOptions] = useState<any[]>([])
  const [borrow, setBorrow] = useState<any>(null)
  const [receiver, setReceiver] = useState<any>({})
  const [isHost, setIsHost] = useState(false)
  const [messagesParams, setMessagesParams] = useState({ page: 1 })
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
  const [scrollBottom, setScrollBottom] = useState({ scroll: true })
  const [bookPoint, setBookPoint] = useState<any>(null)
  const { refresh, setRefresh, setRefreshChatList } = useMessaging()
  const message = useInput("")
  const toast = useToast()
  const [present] = useIonAlert()
  const {isAndroid, safeArea} = useDevice()
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const params: any = match.params
  const { showKeyboard } = useDevice()
  const history: any = useHistory()
  const [qrCodeResult, setQrCodeResult] = useState<any>(null)
  const [messages, setMessages] = useState<any[]>([])
  const filteredMessages = useMemo(() => {
    return messages
      .map(m => {
        m.sentTime = format(new Date(m.created_at), isToday(new Date(m.created_at)) ? "HH:mm" : "dd/MM/yyyy HH:mm")
        m.isSender = b2bUser?.id === m.sender_id && m.type !== messageStatuses.system_message
        if (!m.isSender && m.type !== messageStatuses.system_message) {
          m.receiverAvatar = receiver?.avatar
        }

        return m;
      })
      .sort((a, b) => {
        return a.created_at > b.created_at ? 1 : -1
      })
  }, [messages])

  const cancelBorrow = async () => {
    setLoading(true)

    try {
      await axios.post(`/borrows/${params.id}/cancel`)
      await loadBorrow()
      toast.success("Richiesta di prenotazione cancellata con successo")
      setRefresh(true)
    } catch (error) {
      console.error(error)
      toast.error("Errore nella cancellazione della prenotazione")
    }

    setLoading(false)
  }

  const requestExtension = async () => {
    setLoading(true)

    try {
      await axios.post(`/borrows/${params.id}/extension`)
      await loadBorrow()
      toast.success("Prolungamento richiesto con successo")
      setRefresh(true)
    } catch (error) {
      console.error(error)
      toast.error("Errore durante la richiesta di prolungamento")
    }

    setLoading(false)
  }

  // onMessage(messaging, async (payload) => {
  //   const type = payload?.data?.type
  //   if (type === 'auto-message') {
  //     setLoading(true)
  //     await loadBorrow()
  //     setLoading(false)
  //   } else {
  //     setMessagesParams({ ...messagesParams, page: 1 })
  //   }
  // });

  const sendMessage = async () => {
    if (message.value === "")
      return;

    try {
      setSendMessageLoading(true)

      const { data } = await axios.post(`borrows/${params.id}/send_message`, {
        message: message.value
      })
      // resetto il campo di input per l'invio messaggio
      message.reset()

      setMessages([...messages, ...[data]])

      setScrollBottom({ scroll: true })
      setSendMessageLoading(false)
      setRefresh(true)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel invio del messaggio")
      setSendMessageLoading(false)
    }
  }

  const loadMoreMessages = async (event: any) => {
    setScrollBottom({ scroll: false })
    setMessagesParams({ ...messagesParams, page: messagesParams.page + 1 })
    event.target?.complete()
  }

  const loadHostAndReceiver = () => {
    setIsHost(b2bUser?.id === borrow?.shelf?.user?.id)
    setReceiver(b2bUser?.id === borrow?.user?.id ? borrow?.shelf?.user : borrow?.user)
  }

  const loadOptions = () => {
    let options = []

    if ([borrowStatuses.request_pending, borrowStatuses.sync_address_pending, borrowStatuses.sync_pending].includes(borrow?.status)) {
      options.push({
        label: "Annulla Prenotazione",
        onClick: () => {
          present({
            header: 'Annulla Prenotazione',
            message: 'Sicuro di voler annullare questa prenotazione?',
            buttons: [
              'Indietro',
              { text: 'Ok', handler: () => cancelBorrow() },
            ]
          })
        }
      })
    } else if (borrow?.status === borrowStatuses.active) {
      options.push({
        label: "Richiedi estensione prestito",
        onClick: () => {
          present({
            header: 'Richiedi prolungamento',
            message: 'Sicuro di voler richiedere un prolungamento?',
            buttons: [
              'Indietro',
              { text: 'Ok', handler: () => requestExtension() },
            ]
          })
        }
      })
    }

    setOptions(options)
  }

  const loadMessages = async () => {
    try {
      const { data } = await axios.get(`borrows/${params.id}/messages`, { params: messagesParams })

      // se non ho recuperato messaggi disabilito l'infinite scroll
      setDisableInfiniteScroll(data.length === 0)

      // array dei soli messaggi che già non esistono in messages
      const newMessages = data.filter((m: any) => !messages.find((m2: any) => m2.id === m.id))

      setMessages([...messages, ...newMessages])
    } catch (error) {
      console.error(error)
      toast.error("Errore nel caricamento dei messaggi")
    }
  }

  const loadBorrow = async () => {
    try {
      const { data } = await axios.get(`borrows/${params.id}`)
      setBorrow(data)
      setRefreshChatList(true)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel caricamento della chat")
    }
  }

  const loadBookPointDetails = async (bookpoint_id: string) => {
    try {
      const { data } = await axios.get(`/bookpoints/${bookpoint_id}`)
      setBookPoint(data)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero del dettaglio del BookPoint")
    }
  }

  const loadData = async () => {
    setLoading(true)
    await loadBorrow();
    setLoading(false)
  }

  const resetQrCodeResult = () => {
    setQrCodeResult(null)
  }

  useEffect(() => {
    if (user) {
      loadData()
    }
  }, [user, token])

  useEffect(() => {
    if (b2bUser && borrow) {
      loadHostAndReceiver()
      loadOptions()
      loadMessages()
      setRefresh(false)
    }
  }, [b2bUser, borrow, messagesParams])

  useEffect(() => {
    if (refresh) {
      loadData()
      setRefreshChatList(true)
    }
  }, [refresh])

  useEffect(() => {
    if (borrow?.bookpoint_id && borrow.status === borrowStatuses.sync_pending) {
      loadBookPointDetails(borrow.bookpoint_id)
    }
  }, [borrow])

  // per scrollare quando arriva un nuovo messaggio
  useEffect(() => {
    contentRef.current?.scrollToBottom(0)
    // if (scrollBottom.scroll && filteredMessages.length > 0) {
    //   setTimeout(() => {
    //     contentRef.current?.scrollToBottom(0)
    //   }, 500)
    // }
  }, [filteredMessages, scrollBottom])

  useIonViewDidEnter(() => {
    if (history.location.state && history.location.state.result) {
      let state = { ...history.location.state }

      setQrCodeResult(state.result)
      delete state.result
      history.replace({ ...history.location, state });
    }
  })

  const footerBottom = () => {
    if(isAndroid) {
      return `calc(48px + min(${safeArea?.insets.bottom ?? 0}px, var(--ion-safe-area-bottom)))`
    }
    return 48
  }

  const inputStyle = {
    background: "#eaeaea"
  }
  const chatFooterStyle= {
    backgroundColor: 'white', 
    bottom: footerBottom()
  }

  return (
    <SecondaryLayout
      ref={contentRef}
      toolbar={
        <ChatToolbar
          borrow={borrow}
          bookPoint={bookPoint}
          receiver={receiver}
          present={present}
        />
      }
      footer={
        ![borrowStatuses.request_canceled, borrowStatuses.request_rejected, borrowStatuses.terminated].includes(borrow?.status) ?
          <IonFooter
            style={chatFooterStyle}
            class={showKeyboard ? '' : 'safe-area-footer'}
          >
            <IonGrid>
              {borrow &&
                <PinnedMessage
                  key={borrow?.status}
                  isHost={isHost}
                  borrow={borrow}
                  qrCodeResult={qrCodeResult}
                  router={router}
                  resetQrCodeResult={resetQrCodeResult}
                  onUpdate={() => loadData()}
                />
              }
              {options.length !== 0 &&
                <ChatOption options={options} />
              }
              <IonRow class="ion-align-items-center">
                <IonCol size="11">
                  <IonTextarea
                    rows={1}
                    style={inputStyle}
                    {...message}
                    onKeyPress={(event: any) => {
                      if (event.key === 'Enter' && !sendMessageLoading) {
                        sendMessage()
                      }
                    }}
                  />
                </IonCol>
                <IonCol size="1" class="ion-align-items-center">
                  {!sendMessageLoading ?
                    <IonIcon
                      style={{ fontSize: 25, paddingTop: 5 }}
                      color="primary"
                      icon={send}
                      onClick={sendMessage}
                    />
                    :
                    <IonSpinner name="crescent" color="primary" style={{ fontSize: 25, marginTop: 5 }} />
                  }
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonFooter>
          :
          <IonFooter style={{ backgroundColor: 'white', marginBottom: 48 }} class="safe-area-footer">
            <IonGrid>
              <IonRow>
                <IonCol size="12" class="ion-text-center">
                  <IonText>Questo prestito è archiviato.</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonFooter>
      }
      loading={loading}
    >
      <IonInfiniteScroll
        onIonInfinite={loadMoreMessages}
        position="top"
        threshold="10%"
        disabled={disableInfiniteScroll}
      >
        <IonInfiniteScrollContent
          loadingSpinner="dots"
          loadingText="Caricamento in corso..."
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      {
        filteredMessages.map((m: any, x: number) =>
          <ChatRow key={x} message={m} />
        )
      }
    </SecondaryLayout >
  )
}

export default withRouter(Chat)
