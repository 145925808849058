import {
  IonCard,
  IonText,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  IonAvatar,
} from "@ionic/react"
import Dot from "./Dot"

interface BookPointCardProps {
  bookPoint: BookPoint
  onClick: () => void
}

const BookPointCard: React.FC<BookPointCardProps> = ({ bookPoint, onClick }) => {

  const distance = (d: number) => {
    let ret = 'a '
    ret += d >= 1000 ?
      (Number((d / 1000).toFixed(1))) + ' km'
      :
      d + ' m'
    ret += ' da te'
    return ret
  }

  const cardStyle = {
    background: "#eaeaea",
    height: 'fit-content',
  }

  const imgStyle = {
    display: 'block',
    objectFit: 'cover',
    height: 170,
  }

  return (
    <IonCard
      style={cardStyle}
      onClick={onClick}
    >
      <IonAvatar style={{ position: "absolute", margin: 15 }}>
        <IonImg src="/assets/icon/favicon.png" style={{ width: 60, height: 60 }} />
      </IonAvatar>
      <IonImg
        style={imgStyle}
        src={bookPoint.image ?? "/images/bookpoint-no-picture.jpg"}
      />

      <IonGrid>
        <IonRow>
          <IonCol>
            <IonText><strong>{bookPoint.full_name}</strong></IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          {bookPoint.distance &&
            <IonCol size="9">
              <IonText>
                {distance(bookPoint.distance)}
              </IonText>
            </IonCol>
          }
          <IonCol offset={bookPoint.distance ? "0" : "9"} size="3">
            <Dot open={bookPoint.open} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  )
}

export default BookPointCard

export type BookPoint = {
  id: string,
  address: string,
  alias: string,
  bio: string,
  bookpoint: boolean,
  current_timetables: any[],
  description: string,
  distance: number,
  image: string,
  open: boolean,
  province: string,
  services: Service[],
  timetables: any[],
  borrows_timetables: any[],
  web_site: string
  latitude: string,
  longitude: string,
  full_name: string,
  has_shelfs: boolean,
  city: string,
  zip: string
  extra_info: string
}

export type Service = {
  icon: string,
  label: string
}