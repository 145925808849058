import {
  IonCard,
  IonGrid,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
  IonText,
  IonToolbar,
  ScrollDetail,
  useIonViewDidEnter
} from "@ionic/react"
import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import { Swiper, SwiperSlide } from "swiper/react"
import BookCard from "../components/BookCard"
import Carousel from "../components/Carousel"
import { useToast } from "../providers/ToastProvider"
import { Geolocation, Position } from '@capacitor/geolocation';
import { shelfsWithDistance } from "../plugins/distance"
import HomeLayout from "../layout/HomeLayout"
import { useDevice } from "../providers/DeviceProvider"
import ToastTutorial from "../components/ToastTutorial"
import { useTabs } from "../components/Main"
import {useDebounce} from 'usehooks-ts'

const Home: React.FC = () => {

  const debounce = 750
  const [search, setSearch] = useState<string>("")
  const debouncedValue = useDebounce<string>(search, debounce)
  const [loading, setLoading] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [noShelfs, setNoShelfs] = useState(false)
  const [shelfs, setShelfs] = useState<any>([])
  const [position, setPosition] = useState<Position | null | undefined>(undefined)
  const filteredShelfs = useMemo(() => {
    return shelfs.map((b: any) => {
      const group = b.shelfs.length > 1
      let ret = shelfsWithDistance(b.shelfs, position)
      const books = ret.map((s: any) => {
        return { ...b, shelf_id: s.id, status: s.status, borrow: s.borrow, host: s.user, distance: s.distance }
      })
      return {
        group,
        books
      }
    })
  }, [shelfs, position])
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)
  const [page, setPage] = useState({ page: 1 })
  const [bookId, setBookId] = useState<any>(null)
  const [carousels, setCarousels] = useState<any>([])
  const [infiniteEvent, setInfiniteEvent] = useState<any>()
  const [toolbarHeight, setToolbarHeight] = useState<number>(100)
  const [shrunk, setShrunk] = useState(false)

  const history: any = useHistory()
  const toast = useToast()
  const { statusBarHeight, isAndroid, isWeb } = useDevice()
  const { homeClicked, setHomeClicked } = useTabs()

  const setCurrentPosition = async () => {
    try {
      setPosition(await Geolocation.getCurrentPosition());
    } catch (error: any) {
      // 1 -> permission denied => l'utente non ha accettato di condividere la posizione
      if (error.code !== 1) {
        console.error(error)
        toast.error("Errore nel recupero della posizione")
      }

      setPosition(null);
    }
  };

  const loadCarousel = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get(`carousels`)
      setCarousels(data)
    } catch (error) {
      console.error(error)
      toast.error("Verifica la tua connessione a internet.")
    }

    setLoading(false)
  }

  const loadMoreShelfs = (event: any) => {
    if (!noShelfs) {
      setInfiniteEvent(event)
      setPage({ page: page.page + 1 })
    }
  }

  const loadShelfs = async (refresh = false) => {
    setLoading(true)

    try {
      const { data, status, meta }: any = await axios.get('books', {
        params: {
          search,
          page: page.page,
          book_id: bookId
        }
      })
      // non ho trovato nessuno shelf per questo libro
      if (status === 206) {
        setNoShelfs(true)
        setDisableInfiniteScroll(true)
      } else if (status === 200) {
        setNoShelfs(false)
      }

      setBookId(null)
      setShelfs(refresh ? data : [...shelfs, ...data])
      setDisableInfiniteScroll(data.length < meta.limit || data.length === 0)
    } catch (error) {
      console.error(error)
      toast.error("Errore nel caricamento dei libri")
    }

    infiniteEvent?.target?.complete()

    setLoading(false)
  }

  const refresh = async () => {
    setShelfs([])
    setPage({ page: 1 })
  }

  useEffect(() => {
    loadShelfs()
  }, [page.page])

  useEffect(() => {
    if (homeClicked) {
      setSearch('')
      setHomeClicked(false)
    }
  }, [homeClicked])

  useEffect(() => {
    refresh()
    if (search === "") {
      loadCarousel()
    } else if (search !== "") {
      loadShelfs(true)
    }

    if (search.length !== 0 && toolbarHeight !== 0) {
      setToolbarHeight(0)
      setShrunk(true)
    } else if (search.length === 0 && toolbarHeight === 0 && window.innerWidth < window.innerHeight && window.innerWidth < 650) {
      setToolbarHeight(100)
      setShrunk(false)
    }
    setNoShelfs(false)
  }, [debouncedValue])

  useEffect(() => {
    if (!position)
      setCurrentPosition()
  }, [position])

  useIonViewDidEnter(() => {
    // for search from starred books click 
    if (history.location.state && history.location.state.search) {
      setSearch(history.location.state.search)
      let state = { ...history.location.state }
      delete state.search;
    }

    if (history.location.state && history.location.state.showBannerTutorial) {
      setShowBanner(true)
      let state = { ...history.location.state }
      delete state.showBannerTutorial;
    }

    if (window.innerWidth > window.innerHeight || window.innerWidth > 650) {
      setToolbarHeight(0)
      setShrunk(true)
    }
  })

  const carouselCardClicked = (book: any) => {
    setBookId(book.id)
    setSearch(book.title)
  }

  const toolbarHomeHeight = () => {
    if(isWeb || isAndroid) {
      return 60
    }
    return `calc(var(--ion-safe-area-top, 0) + 60px)`
  }

  const searchBarStyle = {
    background: "light",
    borderRadius: 20,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    height: 60
  }

  const infoCardStyle = {
    color: "info",
    padding: 10,
    marginLeft: 13,
    marginRight: 13
  }

  const toolbarHomeStyle = {
    height: toolbarHomeHeight(),
    paddingBottom: 10,
    borderRadius: '0px 0px 20px 20px',
   // paddingTop: `max(var(--ion-safe-area-top, 0), ${statusBarHeight}px)`
  }

  const changeToolbarHeight = (event: CustomEvent<ScrollDetail>) => {
    if (search.length === 0 && window.innerWidth < 650) {
      if (event.detail.deltaY > 0 && event.detail.currentY > 60) {
        setToolbarHeight(0)
        setShrunk(true)
      } else if (event.detail.deltaY < 0 && event.detail.currentY < 60) {
        setToolbarHeight(100)
        setShrunk(false)
      }
    }
  }

  return (
    <HomeLayout search={search} onIonScroll={changeToolbarHeight} toolbar={
      <>
        <IonToolbar class="ion-no-border" style={toolbarHomeStyle}>
          <IonSearchbar
            debounce={debounce}
            value={search}
            style={searchBarStyle}
            placeholder="Cerca un libro o un autore..."
            onIonInput={e => {
              setSearch(e.target.value!)
            }}
            onIonClear={() => { setSearch("") }}
          />

        </IonToolbar>
        <div className="toolbar-home" style={{ height: toolbarHeight, transition: '0.3s', overflow: shrunk ? 'hidden' : 'visible' }}>
          <div className="toolbar-home-background"></div>
          <IonImg style={{ opacity: shrunk ? 0 : 1, transition: '0.3s', width: '42%', margin: '0px auto', position: 'relative' }} src="images/personaggi-homepage.svg" />
        </div>
      </>
    }>
      {!loading ?
        <>
          {
            search.length === 0 ?
              <IonGrid class="ion-no-padding" style={{ paddingTop: 53, paddingBottom: 30 }}>
                {carousels.daily &&
                  <Carousel title="I libri del giorno" books={carousels.daily} cardClick={carouselCardClicked} />
                }
                {carousels.most_loved &&
                  <Carousel title="I più popolari" books={carousels.most_loved} cardClick={carouselCardClicked} />
                }
                {carousels.bookpoints &&
                  <Carousel title="Novità dei BookPoint" books={carousels.bookpoints} cardClick={carouselCardClicked} />
                }
                {carousels.starred &&
                  <Carousel title="I più scambiati" books={carousels.starred} cardClick={carouselCardClicked} />
                }
                {carousels.short &&
                  <Carousel title="Libri di breve lettura" books={carousels.short} cardClick={carouselCardClicked} />
                }
                {carousels.long &&
                  <Carousel title="Libri di lunga lettura" books={carousels.long} cardClick={carouselCardClicked} />
                }
              </IonGrid>
              :
              <IonGrid class="ion-no-padding" style={{ paddingTop: 33, paddingBottom: 30 }}>
                {noShelfs &&
                  <IonCard class="ion-text-center" color="info" style={infoCardStyle}>
                    <IonText>Questo libro non è più disponibile in nessuna libreria, però potrebbero interessarti questi altri libri:</IonText>
                  </IonCard>
                }
                {
                  filteredShelfs.map((b: any, x: number) => {
                    if (b.group) {
                      return (
                        <Swiper key={x} slidesPerView={1.2} spaceBetween={10} style={{ paddingLeft: 13 }}>
                          {
                            b.books.map((e: any, i: number) => {
                              return <SwiperSlide key={i}>
                                <BookCard
                                  style={{ margin: 0 }}
                                  isInLibrary={false}
                                  book={e}
                                  onClick={() => history.push(`/hostShelf/${e.shelf_id}`)}
                                />
                              </SwiperSlide>
                            })
                          }
                        </Swiper>
                      )
                    } else {
                      return <BookCard
                        isInLibrary={false}
                        key={x}
                        book={b.books[0]}
                        onClick={() => history.push(`/hostShelf/${b.books[0].shelfs[0].id}`)}
                      />
                    }
                  })
                }
                <IonInfiniteScroll
                  threshold="1%"
                  onIonInfinite={loadMoreShelfs}
                  disabled={disableInfiniteScroll}
                >
                  <IonInfiniteScrollContent
                    loadingSpinner="dots"
                    loadingText="Caricamento in corso..."
                  ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </IonGrid>
          }
        </>
        :
        <IonGrid class="ion-no-padding" style={{ paddingTop: 33, paddingBottom: 30 }}>
          {
            Array.from({ length: 4 }, (_, index) =>
              <IonRow key={index} style={{ margin: '0px 12px 13px' }}>
                <IonSkeletonText class="ion-no-margin" animated style={{ height: 160, borderRadius: 30 }} />
              </IonRow>
            )
          }
        </IonGrid>
      }

      <ToastTutorial
        showBanner={showBanner}
        setShowBanner={setShowBanner}
      />
    </HomeLayout >
  )
}

export default Home
