import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { Device, DeviceInfo } from '@capacitor/device'
import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area'
import { Keyboard } from "@capacitor/keyboard";

type DeviceProviderOptions = {
  device: DeviceInfo | null | undefined
  isWeb: Boolean | null | undefined
  isAndroid: Boolean | null | undefined
  statusBarHeight: number
  safeArea: SafeAreaInsets | undefined
  showKeyboard: boolean
};

const DeviceContext = createContext<DeviceProviderOptions | null>(null);

export const useDevice = () => useContext(DeviceContext) as DeviceProviderOptions;

export const DeviceProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [device, setDevice] = useState<DeviceInfo | null>()
  const [isWeb, setIsWeb] = useState<Boolean | null>()
  const [isAndroid, setIsAndroid] = useState<Boolean | null>()
  const [statusBarHeight, setStatusBarHeight] = useState<number>(0)
  const [safeArea, setSafeArea] = useState<SafeAreaInsets>()
  const [showKeyboard, setShowKeyboard] = useState<boolean>(false)

  useEffect(() => {
    Device.getInfo().then(info => {
      setDevice(info)
      setIsWeb(info.platform == 'web')
      setIsAndroid(info.platform == 'android')
    })

    SafeArea.getStatusBarHeight().then(info => {
      setStatusBarHeight(info.statusBarHeight)
    })
    SafeArea.getSafeAreaInsets().then(insets => {
      setSafeArea(insets)
    })
  }, [])

  useEffect(() => {
    if (isWeb !== undefined && !isWeb) {
      Keyboard.addListener('keyboardWillShow', () => {
        setShowKeyboard(true)
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setShowKeyboard(false)
      });
    }

    return function cleanup() {
      if (isWeb !== undefined && !isWeb)
        Keyboard.removeAllListeners()
    };
  }, [isWeb])

  return (
    <DeviceContext.Provider
      value={{ device, isWeb, isAndroid, statusBarHeight, safeArea, showKeyboard }}>
      {children}
    </DeviceContext.Provider>
  );
}