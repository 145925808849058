
import { Capacitor } from "@capacitor/core"
import { StatusBar, Style } from "@capacitor/status-bar"
import {
  IonContent,
  IonHeader,
  IonPage,
  ScrollDetail,
  useIonViewDidEnter,
  useIonViewWillEnter
} from "@ionic/react"
import { PropsWithChildren, ReactNode, useEffect } from "react"
import { useTabs } from "../components/Main"
import { useDevice } from "../providers/DeviceProvider"
import { useAuth } from "../providers/AuthProvider"

interface HomeLayoutProps {
  toolbar: ReactNode
  loading?: boolean
  search: string
  onIonScroll: (event: CustomEvent<ScrollDetail>) => void
}

const HomeLayout: React.FC<PropsWithChildren<HomeLayoutProps>> = ({ children, toolbar, onIonScroll }) => {

  const { setShowTabs } = useTabs()
  // const { statusBarHeight, isAndroid } = useDevice()

  useIonViewWillEnter(async () => {
    setShowTabs(true)
    try {
      if(Capacitor.isPluginAvailable('StatusBar')) {
        await StatusBar.setStyle({ style: Style.Dark })
        await StatusBar.setBackgroundColor({color: '#ed7459'})
      }
    } catch (error) { console.error(error) }
  })

  const homeHeaderStyle = {
    minHeight: 60,
    background: 'linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%)',
    borderRadius: '0px 0px 20px 20px',
  }

  return (
    <IonPage>
      <IonHeader
        class="ion-no-border"
        style={homeHeaderStyle}
      >
        {toolbar}
      </IonHeader>

      <IonContent
        style={{ position: 'relative', top: '-20px' }}
        scrollEvents
        onIonScroll={onIonScroll}
      >
        {children}
      </IonContent>
    </IonPage>
  )
}

export default HomeLayout