import axios from 'axios';
import { signOut } from './firebase'

export const setup = async () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  axios.interceptors.request.use(async (request) => {
    const token = JSON.parse(localStorage.getItem('token') ?? '')
    if (token) {
      request.headers = {
        'Authorization': token
      }
    }

    return request
  })

  axios.interceptors.response.use(async (response) => {
    return { ...response.data, status: response.status };
  }, async (error) => {
    if (error?.response?.status === 401) {
      console.error(error?.response?.data.message)
      await signOut()
      window.location.replace('/login')
    }

    throw error;
  })
}