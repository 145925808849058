import {
  IonTitle,
  IonIcon,
  useIonViewWillLeave,
  useIonViewDidEnter
} from "@ionic/react"
import { closeOutline } from "ionicons/icons"
import SecondaryToolbar from "../components/SecondaryToolbar"
import SecondaryLayout from "../layout/SecondaryLayout"
import { useHistory } from "react-router"
import { readIsbnBarcode, stopScan } from "../plugins/barcode"
import { useToast } from "../providers/ToastProvider"

const QrcodeScanner: React.FC = () => {

  const history: any = useHistory()
  const toast = useToast()

  useIonViewWillLeave(async () => {
    await stopScan()
  })

  useIonViewDidEnter(async () => {

    const qrcode = await readIsbnBarcode()

    if (history.location.state && history.location.state.from) {

      let state = { ...history.location.state }
      if (qrcode)
        history.replace(state.from, { result: qrcode })

      delete state.from
    } else {
      history.goBack()
      toast.warning('Impossibile leggere il qrcode')
    }
  })

  const closeScan = () => {
    if (history.location.state && history.location.state.from) {
      let state = { ...history.location.state }
      delete state.from;
    }

    history.goBack()
  }

  const backIconStyle = {
    fontSize: 20,
    padding: 10
  }

  return (
    <SecondaryLayout
      toolbar={
        <SecondaryToolbar className="secondary-toolbar">
          <IonTitle>
            Scansiona QrCode
          </IonTitle>
          <IonIcon
            style={backIconStyle}
            slot="end"
            icon={closeOutline}
            onClick={closeScan}
          />
        </SecondaryToolbar>
      }
      scrollY={false}
    >
      <div className="scan-box-delimiters" >
        <em></em>
      </div>
    </SecondaryLayout>
  )
}

export default QrcodeScanner