import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonContent,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonInput,
  IonFooter,
  IonRow,
  IonCol,
  IonButton
} from "@ionic/react"
import { Geolocation, Position } from '@capacitor/geolocation';
import axios from "axios"
import { closeOutline } from "ionicons/icons"
import { useEffect, useRef, useState } from "react"
import useInput from "../hooks/useInput"
import { bookpointsWithDistance } from "../plugins/distance"
import { useDevice } from "../providers/DeviceProvider"
import { useToast } from "../providers/ToastProvider"
import { BookPoint } from "./BookPointCard"
import Loading from "./Loading"

interface BookPointSelectionModalProps {
  isOpen: boolean,
  onDidDismiss: () => void
  onSaveSelection: (addressToSync: string | null) => void
}

const BookPointSelectionModal: React.FC<BookPointSelectionModalProps> = ({ isOpen, onDidDismiss, onSaveSelection }) => {

  const [modalLoading, setModalLoading] = useState(false)
  const [checkOther, setCheckOther] = useState(false)
  const [bookpoints, setBookpoints] = useState<BookPoint[]>([])
  const [selectedBookpointId, setSelectedBookpointId] = useState<string | null>(null)
  const [position, setPosition] = useState<Position | null | undefined>(undefined)
  const toast = useToast()
  const address = useInput("")
  const bookPointModalRef = useRef<HTMLIonContentElement>(null)
  const { safeArea, isAndroid, isWeb } = useDevice()

  const setCurrentPosition = async () => {
    try {
      setPosition(await Geolocation.getCurrentPosition());
    } catch (error: any) {
      // 1 -> permission denied => l'utente non ha accettato di condividere la posizione
      if (error.code !== 1) {
        console.error(error)
        toast.error("Errore nel recupero della posizione")
      }

      setPosition(null);
    }
  }

  const getBookpoints = async () => {
    setModalLoading(true)

    try {

      const { data: bookpoints } = await axios.get("bookpoints", { params: { search: "" } })

      setBookpoints(bookpointsWithDistance(bookpoints, position))

    } catch (error) {
      console.error(error)
      toast.error("Errore nel recupero dei bookpoint")
    }

    setModalLoading(false)
  }

  const createAddressToSync = () => {
    let addressToSync: any = {}

    const sb = bookpoints.find(b => b.id === selectedBookpointId)

    if (!checkOther) {
      addressToSync.bookpoint_id = sb?.id
      addressToSync.address = `${sb?.address} ${sb?.city} ${sb?.province}`
    }
    else {
      addressToSync.address = address.value
    }
    onSaveSelection(addressToSync)
  }

  const distance = (d: number) => {
    return d < 1000 ?
      d + " m"
      :
      Number((d / 1000).toFixed(1)) + " km"
  }

  useEffect(() => {
    if (position === undefined)
      setCurrentPosition()
  }, [position])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        bookPointModalRef && bookPointModalRef.current && (bookPointModalRef.current.ontouchmove = function (e: any) {
          e.stopPropagation();
        })
      }, 100)
    }
  }, [isOpen])

  const bookPointModalFooterStyle = {
    paddingBottom: isWeb ? (safeArea?.insets.bottom ?? 0) + 10
      : isAndroid ? (safeArea?.insets.bottom ?? 0) + 30 : safeArea?.insets.bottom
  }

  const inputStyle = {
    background: "#eaeaea",
  }

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      onDidPresent={getBookpoints}
      initialBreakpoint={isAndroid ? 0.95 : 0.99}
      breakpoints={[0, isAndroid ? 0.95 : 0.99]}
    // serve per mostrare l'animazione del modal come card sopra alla pagina stile ios
    // presentingElement={router || undefined}
    >
      <IonHeader>
        <IonToolbar class="light-toolbar">
          <IonTitle>Luogo di incontro</IonTitle>
          <IonIcon style={{ fontSize: 30 }}
            icon={closeOutline}
            onClick={onDidDismiss}
            slot="end"
          />
        </IonToolbar>
      </IonHeader>
      <Loading isLoading={modalLoading} />
      <IonContent ref={bookPointModalRef}>
        <IonRadioGroup
          value={selectedBookpointId}
          onIonChange={(e) => {
            setCheckOther(e.detail.value === "other")
            setSelectedBookpointId(e.detail.value)
          }}
        >
          {
            bookpoints.map((bookpoint: BookPoint, i: number) => {
              return (
                <IonItem key={i}>
                  <IonRadio slot="start" value={bookpoint.id} />
                  {bookpoint.full_name}
                  {bookpoint.distance &&
                    <IonLabel slot="end" color="grey">
                      <small>
                        {distance(bookpoint.distance)}
                      </small>
                    </IonLabel>
                  }
                </IonItem>
              )
            })
          }
          <IonItem>
            <IonLabel>Altro</IonLabel>
            <IonRadio slot="start" value="other" />
          </IonItem>
        </IonRadioGroup>
        {checkOther &&
          <div style={{ padding: 7 }}>
            <IonInput
              style={inputStyle}
              {...address}
            />
          </div>
        }
      </IonContent>
      <IonFooter style={bookPointModalFooterStyle}>
        <IonRow>
          <IonCol>
            <IonButton
              disabled={(checkOther && address.value === "")}
              shape="round"
              expand='block'
              onClick={createAddressToSync}
            >
              Seleziona
            </IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonModal>
  )
}

export default BookPointSelectionModal