import { useState } from "react";

const useInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const reset = () => {
    setValue(initialValue)
  }

  return {
    value,
    onIonChange: handleChange,
    reset
  };
};

export default useInput;
