import { IonText } from "@ionic/react"

interface DotProps {
  open: boolean
}

const Dot: React.FC<DotProps> = ({ open }) => {

  const redDot = {
    height: 12,
    width: 12,
    backgroundColor: "#bb0202",
    borderRadius: "50%",
    display: "inline-block"
  }

  const greenDot = {
    height: 12,
    width: 12,
    backgroundColor: "#028702",
    borderRadius: "50%",
    display: "inline-block"
  }

  return (
    <IonText float-right>
      {open ?
        <><span style={greenDot}></span> Aperto</>
        :
        <><span style={redDot}></span> Chiuso</>
      }
    </IonText>
  )
}

export default Dot